import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../LoginPage/LoginPage.module.css';
import loginImg from '../../Assets/loginImgImg.png';
import { GoEyeClosed } from "react-icons/go";
import { AuthContext } from '../utils/authContext';
import spinner from '../../Assets/spinner.gif';

function LoginPage() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const { setEmail, email, password, setPassword, LoginAction, loading } = useContext(AuthContext);

    const handleLogin = async () => {
        const isSuccess = await LoginAction(); 
        if (isSuccess) {
            navigate('/dashboard'); 
        } else {
            
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            handleLogin(); 
        }
    };

    return (
        <div className={classes.general}>
            <div className={classes.imgSpace}>
                <img src={loginImg} className={classes.LoginImg} alt="Login illustration" />
            </div>
            <div className={classes.inputField}>
                <span><p>H</p><p className={classes.rRed}>R</p><p>M</p></span>
                <div className={classes.botttomFlex}>
                    <div className={classes.Inpcontaer}>
                        <h4>Log In</h4>
                        <p>Log In as an employee </p>

                        <div className={classes.inputContainer}>
                            <div className={classes.Inputcnt}>
                                <label htmlFor='email'>Email address</label>
                                <input
                                    type='email'
                                    id='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyDown={handleKeyDown} 
                                    placeholder='Johndoe@gmail.com'
                                    required
                                />
                            </div>
                        </div>

                        <div className={classes.inputContainer}>
                            <div className={classes.Inputcnt}>
                                <label htmlFor='password'>Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={handleKeyDown} 
                                    required
                                />
                                <p onClick={togglePasswordVisibility}>
                                    {showPassword ? <GoEyeClosed /> : '👁️'}
                                </p>
                            </div>
                        </div>

                        <button
                            style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F' }}
                            disabled={loading}
                            onClick={handleLogin}
                            className={classes.loginBtn}
                        >
                            {loading && (<img src={spinner} alt='Loading' className={classes.spinnerStyle} />)}
                            {loading ? 'Logging in, please wait....' : 'Log In'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;




// import React, { useState, useEffect, useContext } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import classes from '../LoginPage/LoginPage.module.css'
// // import loginImg from '../../Assets/loginImg.svg'
// import loginImg from '../../Assets/loginImgImg.png'
// import profileImg from '../../Assets/profileIcon.svg'
// import axios from 'axios';
// import { Link } from 'react-router-dom'
// import { GoEyeClosed } from "react-icons/go";
// import ErrorModal from '../../Components/LoginErrorModal/loginError'
// import { AuthContext } from '../utils/authContext';
// import 'react-toastify/dist/ReactToastify.css';
// import spinner from '../../Assets/spinner.gif'



// function LoginPage() {
//     const [messageColor, setMessageColor] = useState('');
//     const [showMessage, setShowMessage] = useState('');
//     const navigate = useNavigate();
//     const [showPassword, setShowPassword] = useState(false);

//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };

//     const [isModalOpen, setIsModalOpen] = useState(false);

//     const openModal = () => setIsModalOpen(true);
//     const closeModal = () => setIsModalOpen(false);

//     const { setEmail, email, password, setPassword, LoginAction, loading, setLoading } = useContext(AuthContext)

//     const handleLogin = async () => {
//         const isSuccess = await LoginAction(); // Call LoginAction and get the result
//         if (isSuccess) {
//             navigate('/dashboard'); // Navigate if login is successful
//         } else {
//             openModal(); // Optionally open a modal or handle the error
//         }

//         const handleKeyDown = (event) => {
//             if (event.key === 'Enter') {
//                 event.preventDefault(); // Prevent default form submission or other behavior
//                 handleLogin(); // Trigger login function
//             }
//         };
//     };

//     return (
//         <div className={classes.general}>
//             <div className={classes.imgSpace}>
//                 <img src={loginImg} className={classes.LoginImg} />
//             </div>
//             <div className={classes.inputField}>

//                 <span><p>H</p><p className={classes.rRed}>R</p><p>M</p></span>

//                 <div className={classes.botttomFlex}>

//                     <div className={classes.Inpcontaer}>
//                         <h4>Log In</h4>
//                         <p>Log In as an employee </p>

//                         <div className={classes.inputContainer}>

//                             <div className={classes.Inputcnt}>
//                                 <label htmlFor='email'>Email address</label>
//                                 <input
//                                     type='email'
//                                     id='email'
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     placeholder='Johndoe@gmail.com'
//                                     required />
//                             </div>
//                         </div>
//                         <div className={classes.inputContainer}>
//                             <div className={classes.Inputcnt}>
//                                 <label htmlFor='password'>Password</label>
//                                 <input type={showPassword ? 'text' : 'password'}
//                                     id='password'
//                                     value={password}
//                                     onChange={(e) => setPassword(e.target.value)}
//                                     required />
//                                 <p
//                                     onClick={togglePasswordVisibility}>
//                                     {showPassword ? (
//                                         <GoEyeClosed />
//                                     ) : (
//                                         '👁️'
//                                     )}
//                                 </p>
//                             </div>
//                         </div>

//                         <button
//                             style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F' }}
//                             disabled={loading}
//                             onClick={handleLogin}
//                             className={classes.loginBtn}
//                         >
                            
//                             {loading && (<img src={spinner} alt='' className={classes.spinnerStyle} />)}
//                             {loading ? 'Logging in, please wait....' : 'Log In'}
//                         </button>
//                         {/* <p style={{fontWeight: 900, color: messageColor}}> {showMessage}</p> */}
//                         {/* < ErrorModal isOpen={isModalOpen} onClose={closeModal} className={classes.slideInRight} /> */}
//                         {/* <div className={classes.account}>
//                             <p>Don't have an account? <NavLink to='/signup_employee'>Create Account</NavLink></p>
//                         </div> */}
//                     </div>

//                 </div>
//             </div>
//         </div>
//     )
// }
// export default LoginPage;