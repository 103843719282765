import React, { useState, useEffect } from "react";
import classes from "./appraisalModal.module.css";
import closeX from './CloseX.svg';


const AppraisalModal = ({ isOpen, onClose }) => {
  const [animate, setAnimate] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);


  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);


  if (!isOpen) return null;


  return (
    <>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>Create a goal for yourself</h4>
            <img src={closeX} alt="cancel" onClick={onClose} />
          </div>
          <div className={classes.classInputDiv}>
            <label>Name of Goal</label>
            <input type="text" placeholder="Complete new mobile app feature" />
          </div>
          <div className={classes.classInputDiv}>
            <label>Description</label>
            <textarea rows='5' type="text" placeholder="A new video call feature is to be added to our mobile app, I want to complete the video call feature and make sure it works very well with reactions and backgrounds before the next appraisal" />
          </div>
          <div className={classes.bottomKeyresult}>
            <h5>
              KEY RESULT
            </h5>
            <p>Enter Key result you hope to achieve</p>
            <div className={classes.classInputDivWithBorder}>
              <label>Key result 1</label>
              <input type="text" placeholder="Complete new mobile app feature" />
            </div>

            <button className={classes.addNewTypeshii}>Add new ⊕</button>
          </div>
          <button className={classes.sendGoal}>Send Goal</button>
        </div>
      </div>


    </>
  )
};

export default AppraisalModal;
