import React, { useState } from "react";
import classes from './Shift.module.css';
import Horheader from '../../Components/horheader/horheader';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import { Navbar, Container, Button } from 'react-bootstrap';

const Shift = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                    <Container fluid>
                        {/* Sidebar content for large screens */}
                    </Container>
                </Navbar>
                <Navbar bg="light" expand={false} className="d-md-none">
                    <Container fluid>
                        <Button variant="success" onClick={handleShow} aria-label="Toggle sidebar">
                            ☰
                        </Button>
                    </Container>
                </Navbar>
                <DashboardNav show={show} handleClose={handleClose} />
            </div>
            <div className={classes.maincontent}>
                <Horheader />
                <div className={classes.wholediv1}>
                    <h5 className={classes.ProfileHead}>Shift and Schedules</h5>
                    <p>Schedule for the month of July</p>
                    <div className={classes.tableContainer}>
                        <table className={classes.scheduleTable}>
                            <thead>
                                <tr>
                                    <th>Week</th>
                                    <th>Monday</th>
                                    <th>Tuesday</th>
                                    <th>Wednesday</th>
                                    <th>Thursday</th>
                                    <th>Friday</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>July Week 1</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                </tr>
                                <tr className={classes.highlightRow}>
                                    <td>July Week 2</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                </tr>
                                <tr>
                                    <td>July Week 3</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                </tr>
                                <tr className={classes.highlightRow}>
                                    <td>July Week 4</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>July Week 5</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className={classes.highlightRow}>
                                    <td>July Week 6</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td>08:00am - 05:00pm</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Shift;
