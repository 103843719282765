import React, { useState, useEffect } from 'react';
import classes from './slip.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import logout from '../../Assets/logout.png';
import iconl from '../PaySlip/Frame 119.svg';
import downloadIcon from './import.png';
import { Navbar, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

const Slip = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    readData();
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };

  const downloadPayslip = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://hr-api.emas.ng/api/payroll/employee/download-payslip', {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
        responseType: 'blob', // important to specify the response type as blob for file download
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = './Payslip_download'; // default file name
      a.click();
      window.URL.revokeObjectURL(url); // clean up after download

      toast.success("Payslip downloaded successfully");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        toast.error("Error downloading payslip");
        console.error("Download error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className={classes.sidenav}>
        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
          <Container fluid></Container>
        </Navbar>
        <Navbar bg="light" expand={false} className="d-md-none">
          <Container fluid>
            <Button variant="success" onClick={handleShow}>
              ☰
            </Button>
          </Container>
        </Navbar>
        <DashboardNav show={show} handleClose={handleClose} />
      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <div className={classes.wholediv1}>
          <h5 className={classes.ProfileHead}>Payslip</h5>
          <div className={classes.generalDivNew}>
            <div className={classes.gees}>
              <div>
                <p className={classes.rqsttxt}>New Payslip</p>
                <div className={classes.rqstobj}>
                  <img src={iconl} className={classes.styleicon} alt="icon" />
                  <div className={classes.dtxt}>
                    <p className={classes.rqsttxt2}>Pay slip for July 1st to July 31st</p>
                    <p className={classes.rqsttxt3}>Download your payslip here</p>
                  </div>
                </div>
              </div>
              <div>
                {/* Trigger download on button click */}
                <button onClick={downloadPayslip} className={classes.rqstbtn1} disabled={isLoading}>
                  Download All <img src={downloadIcon} className={classes.download} alt="download" />
                </button>
              </div>
            </div>
          </div>

          {/* Previous Payslips */}
          <div className={classes.nogap}>
            <div className={classes.generalDiv}>
              <div className={classes.gees}>
                <div>
                  <div className={classes.headerAndContent}>
                    <p className={classes.previousPayslipsHeader}>Previous payslips</p>
                    <div className={classes.rqstobj}>
                      <img src={iconl} className={classes.styleicon} alt="icon" />
                      <div className={classes.dtxt}>
                        <p className={classes.rqsttxt2}>Pay slip for June 1st to June 30th</p>
                        <p className={classes.rqsttxt3}>Download your payslip here</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button onClick={downloadPayslip} className={classes.rqstbtn} disabled={isLoading}>
                  Download All <img src={downloadIcon} className={classes.download} alt="download" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toast notifications */}
      <ToastContainer />
    </div>
  );
};

export default Slip;
