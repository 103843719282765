import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sign_Up from './pages/SignUp/Sign_up';
import SignupEmp from './pages/SignUp_Employee/Sign_upEmp';
import PersonalDetails from './pages/PersonalDetails/PersonalDetails';
import NextKin from './pages/NextOfKin/NextKin';
import BankDetails from './pages/BankDetails/BankDetails';
import Referees from './pages/Referees/Referees';
import Welcome from './pages/Welcome/Welcome';
import Dashboard from './Dashboard/Dashboard';
import Sign_UpEmp from './pages/SignUp_Employee/Sign_upEmp';
import DashboardOut from './DashboardClock_Out/DashboardOut';
import Education from './pages/Education/Education';
import Qualification from './pages/Qualification/Qualification';
import Work from './pages/Work/Work';
import Document from './Components/Document/Document';
import NewStruct from './pages/NewStructure/NewStructure';
import Slip from './pages/PaySlip/Slip'
import ProfilePage from './pages/Profile Page/ProfilePage';
import Leave from './pages/Leave/Leave';
import EditProfilePage from './pages/EditProfile Page/EditProfilePage';
import Personal from './pages/PersonalDetail/Personal';
import NextKins from './pages/NextKin/NextKin';
import Refereess from './pages/Refereess/Refereess';
import Welcomes from './pages/Welcomes/Welcomes';
import Form from './Components/LeaveForm/Form';
import LeavePending from './pages/LeavePending/LeavePending';
import LeaveApproved from './pages/LeaveApproved/LeaveApproved';
import LeaveDenied from './pages/LeaveDenied/LeaveDenied';
import LeaveWelcome from './pages/Leave Welcome/leaveWelcome';
import LoginPage from './pages/LoginPage/LoginPage'
import Shift from './pages/Shifts/Shift'
import Attendance from './Components/Attendance/Attendance';
import TaskPages from './pages/TaskPages/TaskPages';
import Receipt from './pages/Receipt/Receipt';
import { AuthProvider } from './pages/utils/authContext';
import Appraisal from './pages/Appraisal/appraisal';
import Payslip from './pages/PaySlip/Payslip';

// import TaxInvoice from './pages/Tax Invoice/TaxInvoice';

function App() {
  return (
    
    <Router>
      <AuthProvider>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        {/* <Route path='/sign_up' element={<Sign_Up />} /> */}
        <Route path='/first_login' element={<SignupEmp />} />
        <Route path='/bank_details' element={<BankDetails />} />
        {/* <Route path='/personal_details' element={<PersonalDetails />} /> */}
        {/* <Route path='/nextof_kin' element={<NextKin />} /> */}
        {/* <Route path='/referees' element={<Referees />} /> */}
        {/* <Route path='/NewStruct' element={<NewStruct />} /> */}
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/appraisal' element={<Appraisal />} />
        {/* <Route path='/dashboard_' element={<DashboardOut />} /> */}
        {/* <Route path='/profile_page' element={<ProfilePage />} /> */}
        <Route path='/education' element={<Education />} />
        <Route path='/personal' element={<Personal />} />
        <Route path='/nextkin' element={<NextKins />} />
        <Route path='/refereess' element={<Refereess />} />
        <Route path='/welcomes' element={<Welcomes />} />
        <Route path='/qualification' element={<Qualification />} />
        <Route path='/attendance' element={<Attendance />} />
        <Route path='/work' element={<Work />} />
      
        <Route path='/slip' element={<Slip />} />
        <Route path='/payslip_download' element={<Payslip />} />
        <Route path='/shift' element={<Shift />} />
        <Route path='/Task' element={<TaskPages />} />

        <Route path='/Document' element={<Document />} />
        <Route path='/leave' element={<Leave />} />
        <Route path='/welcome_' element={<LeaveWelcome />} />
        <Route path='/profile' element={<ProfilePage />} />
        <Route path='/form' element={<Form />} />
        <Route path='/pending' element={<LeavePending />} />
        <Route path='/approved' element={<LeaveApproved />} />
        <Route path='/denied' element={<LeaveDenied />} />
        <Route path='/edit_profile' element={<EditProfilePage />} />
        <Route path='/attendance' element={<Attendance />} />
        <Route path='/login' element={<LoginPage />} />

      </Routes>
      </AuthProvider>
    </Router>
   
  );
}

export default App;