import React, { useState } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import classes from './Leave.module.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logout from '../../Assets/logout.png'
import iconl from '../../Assets/iconleave.png'
import { Navbar, Container, Button } from 'react-bootstrap';



function Leave() {
    const [key, setKey] = useState('Personal Details');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                    <Container fluid>
                    </Container>
                </Navbar>
                <Navbar bg="light" expand={false} className="d-md-none">
                    <Container fluid>
                        <Button variant="success" onClick={handleShow}>
                            ☰
                        </Button>
                    </Container>
                </Navbar>
                <DashboardNav show={show} handleClose={handleClose} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <div className={classes.wholediv1}>

                    <h5 className={classes.ProfileHead}>Leave</h5>
                    <div className={classes.generalDiv}>
                        <div className={classes.gees}>
                            <div>
                                <p className={classes.rqsttxt}>Request for Time off</p>
                                <div className={classes.rqstobj}>
                                    <img src={iconl} className={classes.styleicon} />
                                    <div className={classes.dtxt}>
                                        <p className={classes.rqsttxt2}>No current request for time off</p>
                                        <p className={classes.rqsttxt3}>Recent requests for time off will show here</p>
                                    </div>

                                </div>
                            </div>


                            <div>
                                <Link to="/form" className={classes.rqstbtn}>
                                    Request time off <img src={logout} className={classes.logt} alt="logout" />
                                </Link>                            </div>

                        </div>
                    </div>
                    <div className={classes.generalDiv}>
                        <div className={classes.gees}>
                            <div>
                                <p className={classes.rqsttxt}>Company Leave policy</p>
                                <div className={classes.rqstobj}>
                                    <img src={iconl} className={classes.styleicon} />
                                    <div className={classes.dtxt}>
                                        <p className={classes.rqsttxt2}>21 Leave days available to you for this year</p>
                                        <p className={classes.rqsttxt3}>You have used 5 days out of your available 21 leave days for this year</p>
                                    </div>

                                </div>
                            </div>


                            {/* <div>
                                <button className={classes.rqstbtn}>Request time off <img src={logout} className={classes.logt} /> </button>
                            </div> */}

                        </div>
                    </div>
                    <div className={classes.generalDiv}>
                        <div className={classes.gees}>
                            <div>
                                <p className={classes.rqsttxt}>Leave History</p>
                                <div className={classes.rqstobj}>
                                    <img src={iconl} className={classes.styleicon} />
                                    <div className={classes.dtxt}>
                                        <p className={classes.rqsttxt2}>Leave taken this year</p>
                                        <p className={classes.rqsttxt3}>Leave taken for 5 days between 3rd of February, 2024 to 8th of February, 2024</p>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>


                </div>


            </div>


        </div>

    );
}

export default Leave;