import React, { useState, useEffect } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import classes from './Form.module.css';
import arrow from './arrow.png';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { BASE_URL } from "../../pages/api/api";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

function Form() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reason, setReason] = useState(null);
    const [show, setShow] = useState(false);
    const [bearer, setBearer] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [getType,setGetType] = useState([])
    const [selectedReason, setSelectedReason] = useState(null);

    const navigate = useNavigate();

   
    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the token from storage');
        }
    };

    const headers = {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${bearer}`,
    };

    // const isFormValid = () => {
    //     return startDate && endDate && reason !== null && new Date(startDate) <= new Date(endDate);
    // };

    const fetchBeneficiaries = async (e) => {
       setIsLoading(true)
        e.preventDefault();
        // if (!isFormValid()) {
        //     toast.error('Please fill all fields correctly.');
        //     return;
        // }
        setIsLoading(true);
        // console.log(selectedReason)

        const data = {
            "type":selectedReason.value,
  "start_date": startDate,
  "end_date": endDate  

        };

        try {
            const response = await axios.post(
                `${BASE_URL}/leaveRequest/create`,
                data,
                { headers }
            );
            // console.log(response)
            toast.success(response?.data?.message || 'Leave Request submitted');
            navigate('/welcome_');
        } catch (error) {
            // console.log(error.message)
            if (error.response) {
                // console.error('Error response:', error.response.data?.message);
                toast.error(error.response.data?.message) // Log the entire response for debugging
                if (error.response.data.errors) {
                    Object.entries(error.response.data.errors).forEach(([key, value]) => toast.error(value[0]));
                }
                if (error.response.status === 401) {
                    navigate("/login");
                }
                
            } else {
                toast.error('Network error, please try again later');
            }
        } finally {
            setIsLoading(false);
            setStartDate('');
            setEndDate('');
        }
    };

    const fetchDetailsHandler = async () => {
        console.log('submited from details')
    
        try {
          const response = await axios.get(
            `${BASE_URL}/leave/fetchAll`,
            { headers }
          );
          const results = response?.data?.data?.leave;
          const transformedData = results.map(item => ({
            value: item.id,
            label: item.type
          }));
          setGetType(transformedData)
        //   console.log(transformedData)
          
        } catch (error) {
            // console.log(error)
        //   console.log(error?.response?.data)
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            // navigate("/login");
          } else {
            const errorStatus = error.response?.data?.message;  
            // console.log(errorStatus);
            // setUserData([]);
          }
        } finally {
            // setGetType([])
        }
      };
    
      const handleReasonChange = (selectedOption) => {
        setSelectedReason(selectedOption);
        // You can perform additional actions here if needed
        // console.log("Selected reason:", selectedOption);
      };

      useEffect(() => {
    
        if (bearer) {
          fetchDetailsHandler();
    
        }
      }, [bearer]);
    

    useEffect(() => {
        readData();
    }, []);
   

   
    const leaveReasons = [
        { value: 'family', label: 'Family Reasons' },
        { value: 'medical', label: 'Medical Leave' },
        { value: 'vacation', label: 'Vacation' },
        { value: 'personal', label: 'Personal Reasons' },
        { value: 'other', label: 'Other' }
    ];


    return (
        <div className={classes.appcontainer}>
            {/* {console.log(getType)} */}
            <div className={classes.sidenav}>
                <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                    <Container fluid></Container>
                </Navbar>
                <Navbar bg="light" expand={false} className="d-md-none">
                    <Container fluid>
                        <Button variant="success" onClick={() => setShow(true)}>
                            ☰
                        </Button>
                    </Container>
                </Navbar>
                <DashboardNav show={show} handleClose={() => setShow(false)} />
            </div>
            <div className={classes.maincontent}>
                <Horheader />
                <div className={classes.wholediv1}>
                    <h5 className={classes.ProfileHead}>Leave</h5>
                    <div className={classes.generalDiv}>
                        <div className={classes.gees}>
                            <div className={classes.arrowAndText}>
                                <Link to='/leave'>
                                    <img src={arrow} className={classes.arrowIcon} alt="Arrow icon" />
                                </Link>
                                <p className={classes.rqsttxt}>Request for Time off</p>
                            </div>
                            <div className={classes.dtxt}>
                                <p className={classes.rqsttxt3}>Please fill out the details for your leave request</p>
                            </div>
                        </div>
                        <br />
                        <div className={classes.formContainer}>
                            <form className={classes.form} onSubmit={fetchBeneficiaries}>
                                <div className={classes.formGroup}>
                                    <label htmlFor="reason">Reason for Time off:</label>
                                    <Select
                                        id="reason"
                                        value={selectedReason}
                                        onChange={handleReasonChange}
                                        options={getType}
                                        placeholder='Select a reason'
                                        className={classes.select}
                                    />
                                </div>
                                <div className={classes.formGroupTop}>
                                    <div className={classes.formGroup1}>
                                        <label htmlFor="startDate">Start date:</label>
                                        <input
                                            type="date"
                                            id="startDate"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className={classes.inputs}
                                        />
                                    </div>
                                    <div className={classes.formGroup1}>
                                        <label htmlFor="endDate">End date:</label>
                                        <input
                                            type="date"
                                            id="endDate"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className={classes.inputs}
                                        />
                                    </div>
                                </div>
                                <button className={classes.submitButton} type="submit" >
                                    <p>{isLoading ? 'Uploading...' : 'Submit Request'}</p>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default Form;
