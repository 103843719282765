import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Updated import
import classes from "./Personal.module.css";
import Sidebar from "../Sidebar/Sidebar";
import { BiRightArrowAlt } from "react-icons/bi";
import { Navbar, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { BASE_URL } from "../api/api";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
// import { NaijaStates } from 'naija-state-local-government';




const Personal = () => {
  const [formData, setFormData] = useState([
    {
      schoolName: "",
      degree: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
    },
    {
      schoolName: "",
      degree: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
    },
  ]);

  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [bloodGroup,setBloodGroup] = useState("")
  const [genotype,setGenotype] = useState("")
  const [localGov,setLocalGov] = useState("")
  const [maritalStatus,setMaritalStatus] = useState("")
  const [otherName,setOtherName] = useState("")
  const [nationality, setNationality] = useState("");
  const [residentialAdrss,setResidentialAdrss] = useState("")
  const [permanentAdrss,setPermanentAdrss] = useState("")
  const [placeOfBirth,setPlaceOfBirth] = useState("")
  const [religion,setReligion] = useState("")
  const [origin,setOrigin] = useState("")
  const [title,setTitle] = useState("")
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedLgas, setSelectedLgas] = useState(null);
  const [localGovernmentOptions, setLocalGovernmentOptions] = useState([]);
 

  const navigate = useNavigate(); // Updated hook

  

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newFormData = [...formData];
    newFormData[index][name] = value;
    setFormData(newFormData);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(formData);
  // };

  const addNewProgram = () => {
    setFormData([
      ...formData,
      {
        schoolName: "",
        degree: "",
        fieldOfStudy: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

    const readData = async () => {
      try {
          const value = await AsyncStorage.getItem('tokens');
          // console.log(value)
          if (value !== null) {
              setBearer(value);
          }
      } catch (e) {
          alert('Failed to fetch the token from storage');
      }
  };

  const headers = {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${bearer}`,
  };

  const fetchBeneficiaries = async () => {
    console.log('submited')

    setIsLoading(true);

    const data = {
      first_name: firstName,
      date_of_birth: birthDate,
      last_name: lastName,
      email: email,
      phone: number,
      gender: gender,
      nationality:nationality,
      blood_group: bloodGroup,
      genotype:genotype,
      home_address:residentialAdrss,
      local_gov:selectedLgas?.value || localGov,
      marital_status:maritalStatus,
      other_name:otherName,
      permanent_address:permanentAdrss,
      place_of_birth:placeOfBirth,
      religion:religion,
      state_of_origin:selectedState?.value || origin,
      title:title
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/employees/update/personal-details/create`,
        data,
        { headers }
      );
      const results = response.data?.data;
      navigate('/education')
    } catch (error) {
      console.log(error?.response?.data?.message)
      Object.entries(error?.response?.data?.message).forEach(([key,value]) => toast.error(value[0]) )
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
       toast.error(errorStatus);
        // setUserData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const fetchDetailsHandler = async () => {
    console.log('submited from details')

    try {
      const response = await axios.get(
        `${BASE_URL}/employees/update/personal-details`,
        { headers }
      );
      const results = response?.data?.data;
      // setUserData(results);
      setFirstName(results?.first_name)
      setLastName(results?.last_name)
      setEmail(results?.email)
      setNumber(results?.phone)
      setGender(results?.gender)
      setBirthDate(results?.date_of_birth)
      setHomeAddress(results?.home_address)
      setNationality(results?.nationality)
      setOtherName(results?.other_name)
      setTitle(results?.title)
      setPlaceOfBirth(results?.place_of_birth)
      setMaritalStatus(results?.marital_status)
      setOrigin(results?.state_of_origin)
      setLocalGov(results?.local_gov)
      setReligion(results?.religion)
      setBloodGroup(results?.blood_group)
      setGenotype(results?.genotype)
      setResidentialAdrss(results?.home_address)
      setPermanentAdrss(results?.permanent_address)
      
    } catch (error) {
      console.log(error?.response?.data)
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        // navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;  
        console.log(errorStatus);
        setUserData([]);
      }
    } finally {
      
    }
  };


  useEffect(() => {

    readData();
    }, []);

  useEffect(() => {

    if (bearer) {
      fetchDetailsHandler();

    }
  }, [bearer]);


  

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const nigerianStates = [
    { value: 'abia', label: 'Abia' },
    { value: 'adamawa', label: 'Adamawa' },
    { value: 'akwa_ibom', label: 'Akwa Ibom' },
    { value: 'anambra', label: 'Anambra' },
    { value: 'bauchi', label: 'Bauchi' },
    { value: 'bayelsa', label: 'Bayelsa' },
    { value: 'benue', label: 'Benue' },
    { value: 'borno', label: 'Borno' },
    { value: 'cross_river', label: 'Cross River' },
    { value: 'delta', label: 'Delta' },
    { value: 'ebonyi', label: 'Ebonyi' },
    { value: 'edo', label: 'Edo' },
    { value: 'ekiti', label: 'Ekiti' },
    { value: 'enugu', label: 'Enugu' },
    { value: 'gombe', label: 'Gombe' },
    { value: 'imo', label: 'Imo' },
    { value: 'jigawa', label: 'Jigawa' },
    { value: 'kaduna', label: 'Kaduna' },
    { value: 'kano', label: 'Kano' },
    { value: 'katsina', label: 'Katsina' },
    { value: 'kebbi', label: 'Kebbi' },
    { value: 'kogi', label: 'Kogi' },
    { value: 'kwara', label: 'Kwara' },
    { value: 'lagos', label: 'Lagos' },
    { value: 'nasarawa', label: 'Nasarawa' },
    { value: 'niger', label: 'Niger' },
    { value: 'ogun', label: 'Ogun' },
    { value: 'ondo', label: 'Ondo' },
    { value: 'osun', label: 'Osun' },
    { value: 'oyo', label: 'Oyo' },
    { value: 'plateau', label: 'Plateau' },
    { value: 'rivers', label: 'Rivers' },
    { value: 'sokoto', label: 'Sokoto' },
    { value: 'taraba', label: 'Taraba' },
    { value: 'yobe', label: 'Yobe' },
    { value: 'zamfara', label: 'Zamfara' },
    { value: 'fct', label: 'Federal Capital Territory (FCT)' }
  ];
  


  const localGovernments = {
    abia: ['Aba North', 'Aba South', 'Arochukwu', 'Bende', 'Ikwuano', 'Isiala Ngwa North', 'Isiala Ngwa South', 'Isuikwuato', 'Obi Ngwa', 'Ohafia', 'Osisioma', 'Ugwunagbo', 'Ukwa East', 'Ukwa West', 'Umuahia North', 'Umuahia South', 'Umu Nneochi'],
    adamawa: ['Demsa', 'Fufore', 'Ganye', 'Girei', 'Gombi', 'Guyuk', 'Hong', 'Jada', 'Lamurde', 'Madagali', 'Maiha', 'Mayo-Belwa', 'Michika', 'Mubi North', 'Mubi South', 'Numan', 'Shelleng', 'Song', 'Toungo', 'Yola North', 'Yola South'],
    akwa_ibom: ['Abak', 'Eastern Obolo', 'Eket', 'Esit Eket', 'Essien Udim', 'Etim Ekpo', 'Etinan', 'Ibeno', 'Ibesikpo Asutan', 'Ibiono-Ibom', 'Ika', 'Ikono', 'Ikot Abasi', 'Ikot Ekpene', 'Ini', 'Itu', 'Mbo', 'Mkpat-Enin', 'Nsit-Atai', 'Nsit-Ibom', 'Nsit-Ubium', 'Obot Akara', 'Okobo', 'Onna', 'Oron', 'Oruk Anam', 'Udung-Uko', 'Ukanafun', 'Uruan', 'Urue-Offong/Oruko', 'Uyo'],
    anambra: ['Aguata', 'Anambra East', 'Anambra West', 'Anaocha', 'Awka North', 'Awka South', 'Ayamelum', 'Dunukofia', 'Ekwusigo', 'Idemili North', 'Idemili South', 'Ihiala', 'Njikoka', 'Nnewi North', 'Nnewi South', 'Ogbaru', 'Onitsha North', 'Onitsha South', 'Orumba North', 'Orumba South', 'Oyi'],
    bauchi: ['Alkaleri', 'Bauchi', 'Bogoro', 'Damban', 'Darazo', 'Dass', 'Gamawa', 'Ganjuwa', 'Giade', 'Itas/Gadau', 'Jama\'are', 'Katagum', 'Kirfi', 'Misau', 'Ningi', 'Shira', 'Tafawa Balewa', 'Toro', 'Warji', 'Zaki'],
    bayelsa: ['Brass', 'Ekeremor', 'Kolokuma/Opokuma', 'Nembe', 'Ogbia', 'Sagbama', 'Southern Ijaw', 'Yenagoa'],
    benue: ['Ado', 'Agatu', 'Apa', 'Buruku', 'Gboko', 'Guma', 'Gwer East', 'Gwer West', 'Katsina-Ala', 'Konshisha', 'Kwande', 'Logo', 'Makurdi', 'Obi', 'Ogbadibo', 'Ohimini', 'Oju', 'Okpokwu', 'Otukpo', 'Tarka', 'Ukum', 'Ushongo', 'Vandeikya'],
    borno: ['Abadam', 'Askira/Uba', 'Bama', 'Bayo', 'Biu', 'Chibok', 'Damboa', 'Dikwa', 'Gubio', 'Guzamala', 'Gwoza', 'Hawul', 'Jere', 'Kaga', 'Kala/Balge', 'Konduga', 'Kukawa', 'Kwaya Kusar', 'Mafa', 'Magumeri', 'Maiduguri', 'Marte', 'Mobbar', 'Monguno', 'Ngala', 'Nganzai', 'Shani'],
    cross_river: ['Abi', 'Akamkpa', 'Akpabuyo', 'Bakassi', 'Bekwarra', 'Biase', 'Boki', 'Calabar Municipal', 'Calabar South', 'Etung', 'Ikom', 'Obanliku', 'Obubra', 'Obudu', 'Odukpani', 'Ogoja', 'Yakurr', 'Yala'],
    delta: ['Aniocha North', 'Aniocha South', 'Bomadi', 'Burutu', 'Ethiope East', 'Ethiope West', 'Ika North East', 'Ika South', 'Isoko North', 'Isoko South', 'Ndokwa East', 'Ndokwa West', 'Okpe', 'Oshimili North', 'Oshimili South', 'Patani', 'Sapele', 'Udu', 'Ughelli North', 'Ughelli South', 'Ukwuani', 'Uvwie', 'Warri North', 'Warri South', 'Warri South West'],
    ebonyi: ['Abakaliki', 'Afikpo North', 'Afikpo South', 'Ebonyi', 'Ezza North', 'Ezza South', 'Ikwo', 'Ishielu', 'Ivo', 'Izzi', 'Ohaozara', 'Ohaukwu', 'Onicha'],
    edo: ['Akoko-Edo', 'Egor', 'Esan Central', 'Esan North-East', 'Esan South-East', 'Esan West', 'Etsako Central', 'Etsako East', 'Etsako West', 'Igueben', 'Ikpoba-Okha', 'Oredo', 'Orhionmwon', 'Ovia North-East', 'Ovia South-West', 'Owan East', 'Owan West', 'Uhunmwonde'],
    ekiti: ['Ado-Ekiti', 'Efon', 'Ekiti East', 'Ekiti South-West', 'Ekiti West', 'Emure', 'Gbonyin', 'Ido/Osi', 'Ijero', 'Ikere', 'Ikole', 'Ilejemeje', 'Irepodun/Ifelodun', 'Ise/Orun', 'Moba', 'Oye'],
    enugu: ['Aninri', 'Awgu', 'Enugu East', 'Enugu North', 'Enugu South', 'Ezeagu', 'Igbo Etiti', 'Igbo Eze North', 'Igbo Eze South', 'Isi Uzo', 'Nkanu East', 'Nkanu West', 'Nsukka', 'Oji River', 'Udenu', 'Udi', 'Uzo-Uwani'],
    gombe: ['Akko', 'Balanga', 'Billiri', 'Dukku', 'Funakaye', 'Gombe', 'Kaltungo', 'Kwami', 'Nafada', 'Shongom', 'Yamaltu/Deba'],
    imo: ['Aboh Mbaise', 'Ahiazu Mbaise', 'Ehime Mbano', 'Ezinihitte', 'Ideato North', 'Ideato South', 'Ihitte/Uboma', 'Ikeduru', 'Isiala Mbano', 'Isu', 'Mbaitoli', 'Ngor Okpala', 'Njaba', 'Nkwerre', 'Nwangele', 'Obowo', 'Oguta', 'Ohaji/Egbema', 'Okigwe', 'Onuimo', 'Orlu', 'Orsu', 'Oru East', 'Oru West', 'Owerri Municipal', 'Owerri North', 'Owerri West'],
    jigawa: ['Auyo', 'Babura', 'Biriniwa', 'Birnin Kudu', 'Buji', 'Dutse', 'Gagarawa', 'Garki', 'Gumel', 'Guri', 'Gwaram', 'Gwiwa', 'Hadejia', 'Jahun', 'Kafin Hausa', 'Kaugama', 'Kazaure', 'Kiri Kasama', 'Kiyawa', 'Maigatari', 'Malam Madori', 'Miga', 'Ringim', 'Roni', 'Sule Tankarkar', 'Taura', 'Yankwashi'],
    kaduna: ['Birnin Gwari', 'Chikun', 'Giwa', 'Igabi', 'Ikara', 'Jaba', "Jema'a", 'Kachia', 'Kaduna North', 'Kaduna South', 'Kagarako', 'Kajuru', 'Kaura', 'Kauru', 'Kubau', 'Kudan', 'Lere', 'Makarfi', 'Sabon Gari', 'Sanga', 'Soba', 'Zangon Kataf', 'Zaria'],
    kano: ['Ajingi', 'Albasu', 'Bagwai', 'Bebeji', 'Bichi', 'Bunkure', 'Dala', 'Dambatta', 'Dawakin Kudu', 'Dawakin Tofa', 'Doguwa', 'Fagge', 'Gabasawa', 'Garko', 'Garun Mallam', 'Gaya', 'Gezawa', 'Gwale', 'Gwarzo', 'Kabo', 'Kano Municipal', 'Karaye', 'Kibiya', 'Kiru', 'Kumbotso', 'Kunchi', 'Kura', 'Madobi', 'Makoda', 'Minjibir', 'Nasarawa', 'Rano', 'Rimin Gado', 'Rogo', 'Shanono', 'Sumaila', 'Takai', 'Tarauni', 'Tofa', 'Tsanyawa', 'Tudun Wada', 'Ungogo', 'Warawa', 'Wudil'],
    katsina: ['Bakori', 'Batagarawa', 'Batsari', 'Baure', 'Bindawa', 'Charanchi', 'Dandume', 'Danja', 'Dan Musa', 'Daura', 'Dutsi', 'Dutsin Ma', 'Faskari', 'Funtua', 'Ingawa', 'Jibia', 'Kafur', 'Kaita', 'Kankara', 'Kankia', 'Katsina', 'Kurfi', 'Kusada', 'Mai\'Adua', 'Malumfashi', 'Mani', 'Mashi', 'Matazu', 'Musawa', 'Rimi', 'Sabuwa', 'Safana', 'Sandamu', 'Zango'],
    kebbi: ['Aleiro', 'Arewa Dandi', 'Argungu', 'Augie', 'Bagudo', 'Birnin Kebbi', 'Bunza', 'Dandi', 'Fakai', 'Gwandu', 'Jega', 'Kalgo', 'Koko/Besse', 'Maiyama', 'Ngaski', 'Sakaba', 'Shanga', 'Suru', 'Wasagu/Danko', 'Yauri', 'Zuru'],
    kogi: ['Adavi', 'Ajaokuta', 'Ankpa', 'Bassa', 'Dekina', 'Ibaji', 'Idah', 'Igalamela-Odolu', 'Ijumu', 'Kabba/Bunu', 'Kogi', 'Lokoja', 'Mopa-Muro', 'Ofu', 'Ogori/Magongo', 'Okehi', 'Okene', 'Olamaboro', 'Omala', 'Yagba East', 'Yagba West'],
    kwara: ['Asa', 'Baruten', 'Edu', 'Ekiti', 'Ifelodun', 'Ilorin East', 'Ilorin South', 'Ilorin West', 'Irepodun', 'Isin', 'Kaiama', 'Moro', 'Offa', 'Oke Ero', 'Oyun', 'Pategi'],
    lagos: ['Agege', 'Ajeromi-Ifelodun', 'Alimosho', 'Amuwo-Odofin', 'Apapa', 'Badagry', 'Epe', 'Eti-Osa', 'Ibeju-Lekki', 'Ifako-Ijaiye', 'Ikeja', 'Ikorodu', 'Kosofe', 'Lagos Island', 'Lagos Mainland', 'Mushin', 'Ojo', 'Oshodi-Isolo', 'Shomolu', 'Surulere'],
    nasarawa: ['Akwanga', 'Awe', 'Doma', 'Karu', 'Keana', 'Keffi', 'Kokona', 'Lafia', 'Nasarawa', 'Nasarawa Eggon', 'Obi', 'Toto', 'Wamba'],
    niger: ['Agaie', 'Agwara', 'Bida', 'Borgu', 'Bosso', 'Chanchaga', 'Edati', 'Gbako', 'Gurara', 'Katcha', 'Kontagora', 'Lapai', 'Lavun', 'Magama', 'Mariga', 'Mashegu', 'Mokwa', 'Moya', 'Paikoro', 'Rafi', 'Rijau', 'Shiroro', 'Suleja', 'Tafa', 'Wushishi'],
    ogun: ['Abeokuta North', 'Abeokuta South', 'Ado-Odo/Ota', 'Egbado North', 'Egbado South', 'Ewekoro', 'Ifo', 'Ijebu East', 'Ijebu North', 'Ijebu North East', 'Ijebu Ode', 'Ikenne', 'Imeko Afon', 'Ipokia', 'Obafemi Owode', 'Odeda', 'Odogbolu', 'Ogun Waterside', 'Remo North', 'Shagamu'],
    ondo: ['Akoko North-East', 'Akoko North-West', 'Akoko South-East', 'Akoko South-West', 'Akure North', 'Akure South', 'Ese Odo', 'Idanre', 'Ifedore', 'Ilaje', 'Ile Oluji/Okeigbo', 'Irele', 'Odigbo', 'Okitipupa', 'Ondo East', 'Ondo West', 'Ose', 'Owo'],
    osun: ['Atakumosa East', 'Atakumosa West', 'Aiyedaade', 'Aiyedire', 'Boluwaduro', 'Boripe', 'Ede North', 'Ede South', 'Egbedore', 'Ejigbo', 'Ife Central', 'Ife East', 'Ife North', 'Ife South', 'Ifedayo', 'Ifelodun', 'Ila', 'Ilesa East', 'Ilesa West', 'Irepodun', 'Irewole', 'Isokan', 'Iwo', 'Obokun', 'Odo Otin', 'Ola Oluwa', 'Olorunda', 'Oriade', 'Orolu'],
    oyo: ['Afijio', 'Akinyele', 'Atiba', 'Atisbo', 'Egbeda', 'Ibadan North', 'Ibadan North-East', 'Ibadan North-West', 'Ibadan South-East', 'Ibadan South-West', 'Ibarapa Central', 'Ibarapa East', 'Ibarapa North', 'Ido', 'Irepo', 'Iseyin', 'Itesiwaju', 'Iwajowa', 'Kajola', 'Lagelu', 'Ogbomosho North', 'Ogbomosho South', 'Ogo Oluwa', 'Olorunsogo', 'Oluyole', 'Ona Ara', 'Orelope', 'Ori Ire', 'Oyo East', 'Oyo West', 'Saki East', 'Saki West', 'Surulere'],
    plateau: ['Barkin Ladi', 'Bassa', 'Bokkos', 'Jos East', 'Jos North', 'Jos South', 'Kanam', 'Kanke', 'Langtang North', 'Langtang South', 'Mangu', 'Mikang', 'Pankshin', 'Qua\'an Pan', 'Riyom', 'Shendam', 'Wase'],
    rivers: ['Abua/Odual', 'Ahoada East', 'Ahoada West', 'Akuku-Toru', 'Andoni', 'Asari-Toru', 'Bonny', 'Degema', 'Eleme', 'Emohua', 'Etche', 'Gokana', 'Ikwerre', 'Khana', 'Obio/Akpor', 'Ogba/Egbema/Ndoni', 'Ogu/Bolo', 'Okrika', 'Omuma', 'Opobo/Nkoro', 'Oyigbo', 'Port Harcourt', 'Tai'],
    sokoto: ['Binji', 'Bodinga', 'Dange Shuni', 'Gada', 'Goronyo', 'Gudu', 'Gwadabawa', 'Illela', 'Isa', 'Kebbe', 'Kware', 'Rabah', 'Sabon Birni', 'Shagari', 'Silame', 'Sokoto North', 'Sokoto South', 'Tambuwal', 'Tangaza', 'Tureta', 'Wamako', 'Wurno', 'Yabo'],
    taraba: ['Ardo Kola', 'Bali', 'Donga', 'Gashaka', 'Gassol', 'Ibi', 'Jalingo', 'Karim Lamido', 'Kumi', 'Lau', 'Sardauna', 'Takum', 'Ussa', 'Wukari', 'Yorro', 'Zing'],
    yobe: ['Bade', 'Bursari', 'Damaturu', 'Fika', 'Fune', 'Geidam', 'Gujba', 'Gulani', 'Jakusko', 'Karasuwa', 'Machina', 'Nangere', 'Nguru', 'Potiskum', 'Tarmuwa', 'Yunusari', 'Yusufari'],
    zamfara: ['Anka', 'Bakura', 'Birnin Magaji/Kiyaw', 'Bukkuyum', 'Bungudu', 'Gummi', 'Gusau', 'Kaura Namoda', 'Maradun', 'Maru', 'Shinkafi', 'Talata Mafara', 'Tsafe', 'Zurmi'],
    fct: ['Abaji', 'Bwari', 'Gwagwalada', 'Kuje', 'Kwali', 'Municipal Area Council']
  }




const handleStateChange = (selectedOption) => {
      setSelectedState(selectedOption);
      setSelectedLgas(null);


      const lgas = localGovernments[selectedOption?.value] || [];
      setLocalGovernmentOptions(lgas.map(lga => ({ value: lga, label: lga })));

      setOrigin(selectedOption?.value);
    };
  

  const handlGovChange = (selectedOption) => {
    setSelectedLgas(selectedOption);
    setLocalGov(selectedOption?.value);
  }
  
  return (
    <div className={classes.container}>
      <Navbar bg="light" expand={false} className="d-md-none">
        <Container fluid>
          <Button variant="success" onClick={handleShow}>
            ☰
          </Button>
          <Navbar.Brand href="#">HRM</Navbar.Brand>
        </Container>
      </Navbar>

      <Sidebar show={show} handleClose={handleClose} />

      <div className={classes.contentSpace}>
        {/* <section className={classes.skip}>
          <Link to="/education">
            <a href="">
              Skip
              <BiRightArrowAlt className={classes.arrowIcon} />
            </a>
          </Link>
        </section> */}
        <div className={classes.heading}>
          <h3>Personal details</h3>
          <p>Enter your personal details</p>
        </div>

      
          <div >
            <h6 style={{ textAlign: 'left', paddingLeft:'50px' }}></h6>
            <div className={classes.formContainer}>
              <br />
              <form className={classes.form}>
                <div className={classes.formGroup}>
                  <label htmlFor="Name">First Name</label>
                  <input
                    type="text"
                    id="name"
                    name="schoolName"
                    placeholder='John'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="degree"
                    placeholder='Doe'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="Name">Other Name</label>
                  <input
                    type="text"
                    id="name"
                    name="schoolName"
                    placeholder='Oba'
                    value={otherName}
                    onChange={(e) => setOtherName(e.target.value)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                <label htmlFor="title">Title</label>
                  <select className={classes.inputs} value={title} onChange={(e) => setTitle(e.target.value) } required>
                    <option value="">Select title</option>
                    <option value="mr">Mr.</option>
                    <option value="mrs">Mrs.</option>
                    <option value="miss">Miss.</option>
                    <option value="chief">Chief</option>
                    <option value="honourable">Honourable</option>
                  </select>
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    value={email}
                   onChange={(e) => setEmail(e.target.alue)}
                    name="startDate"

                  
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="phnum">Phone number</label>
                  <input
                    type="text"
                    name="endDate"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                   
                   
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="gender">Gender</label>
                  <select className={classes.inputs} value={gender} onChange={(e) => setGender(e.target.value)} required >
                    <option value="">Select value</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="nationality">Nationality</label>
                  <select className={classes.inputs} value={nationality} onChange={(e) => setNationality(e.target.value) }>
                    <option value="">Select Nationality</option>
                    <option value="Nigeria">Nigeria</option>
                  </select>
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="date">Date of birth</label>
                  <input
                    type="date"
                    name="startDate"
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="pob">Place of Birth</label>
                  <input
                    type="text"
                    name="degree"
                    placeholder='Abeokuta'
                    className={classes.inputs}
                    value={placeOfBirth}
                    onChange={(e) => setPlaceOfBirth(e.target.value) }
                  />
                </div>
                <div className={classes.formGroup}>
                <label htmlFor="marital">Marital Status</label>
                  <select className={classes.inputs} value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value) } required>
                    <option value="">Select Marital Status</option>
                    <option value="single">Single</option>
                    <option  value="married">Married</option>
                  </select>
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="state">State of Origin</label>
                              <Select
                    value={selectedState}
                    onChange={handleStateChange}
                    options={nigerianStates}
                    placeholder="Select a state"
                    isSearchable
                    className={classes.inputsReactSelect}
                  />
                  {/* <input
                    type="text"
                    id="name"
                    name="schoolName"
                    placeholder='Ogun State'
                    
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                  /> */}
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="local">L.G.A</label>
                  <Select
                    value={selectedLgas}
                    onChange={handlGovChange}
                    options={localGovernmentOptions}
                    placeholder="Select a state"
                    isSearchable
                    className={classes.inputsReactSelect}
                  />
                  {/* <input
                    type="text"
                    name="degree"
                    placeholder='Abeokuta South'
                    className={classes.inputs}
                    value={localGov}
                    onChange={(e) => setLocalGov(e.target.value)}
                  /> */}
                </div>
                <div className={classes.formGroup}>
                <label htmlFor="religion">Religion</label>
                  <select className={classes.inputs} value={religion} onChange={(e) => setReligion(e.target.value)} required>
                    <option value="">Select Religion</option>
                    <option value="christianity">Christianity</option>
                    <option value="islam">Islam</option>
                    <option value="other">Others</option>
                  </select>
                </div>
               
                <div className={classes.formGroup}>
                <label htmlFor="blood">Blood Group</label>
                  <select className={classes.inputs} value={bloodGroup} onChange={(e) => setBloodGroup(e.target.value) }  required>
                    <option value="">Select Blood Group</option>
                    <option value="o+">O+</option>
                    <option value="o-">O-</option>
                    <option value="ab">AB</option>
                  </select>
                </div>
               
                <div className={classes.formGroup}>
                <label htmlFor="gen">Genotype</label>
                  <select className={classes.inputs} value={genotype} onChange={(e) => setGenotype(e.target.value)} required>
                    <option value="">Select Genotype</option>
                    <option value="aa">AA</option>
                    <option value="aa">AA</option>
                    <option value="as">AS</option>
                    <option value="ss">SS</option>
                  </select>
                </div>
               
               
               
                <div className={classes.formGroup}>
                  <label htmlFor="address">Residential address</label>
                  <textarea className={classes.inputs} value={residentialAdrss} onChange={(e) => setResidentialAdrss(e.target.value)}>

                  </textarea>

                </div>

                <div className={classes.formGroup}>
                  <label htmlFor="address">Permanant address</label>
                  <textarea className={classes.inputs}  value={permanentAdrss} onChange={(e) =>setPermanentAdrss(e.target.value) }>

                  </textarea>

                </div>
                
              </form>
            </div>
            <br />
          </div>
        
        <div className={classes.newDiv}>
          <button
            className={`btn btn-success ${classes.btn}`}
            style={{ textAlign: "center" }} onClick={fetchBeneficiaries}
          >
           {isLoading ? 'Uploading...' : 'Next'}
          </button>
        </div>
      </div>
    </div>
    
  );
};

export default Personal;