import React, { useState, useEffect } from 'react';
import DashboardNav from '../Components/Navigation.js/Navigation';
import Horheader from '../Components/horheader/horheader';
import classes from './Dashboard.module.css';
import pysf from '../Assets/payurfees.png';
import hostlfees from '../Assets/hostlfees.png';
import fundurwlt from '../Assets/fundurwlt.png';
import chekurrslt from '../Assets/chkurwlt.png';
import gturcrsmtrls from '../Assets/gturcrsmtrls.png';
import rgstrurcurses from '../Assets/rgstrurcurses.png';
import { Link } from 'react-router-dom';
import firstImg from '../Assets/dashboardfirst.svg';
import defaultImg from '../Assets/default_img.svg'
import T_Icon from '../Assets/T Icon.svg';
import M_Icon from '../Assets/M_Icom.svg';
import Q_Icon from '../Assets/QIcon.svg';
import TheImg from '../Assets/BottomIcon.svg';
import { Navbar, Container, Button } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bearer, setBearer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [clockTime, setClockTime] = useState(false);
  const [personal, setPersonal] = useState({});
  const handleClockTime = () => {
    setClockTime(!clockTime);
  }


  useEffect(() => {
    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null && value !== bearer) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the token from storage');
        }
    };
    readData();
}, [bearer]);

// Fetch job details when modal is open
useEffect(() => {
    if (bearer) {
        const fetchJobDetails = async () => {
            setErrorMessage('');
            try {
                const response = await axios.get('https://hr-api.emas.ng/api/employees/update/personal-details', {
                    headers: {
                        Authorization: `Bearer ${bearer}`,
                    },
                });
                setPersonal(response.data.data || []);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching job details:', error);
                setErrorMessage('Failed to fetch job details');
            }
        };
        fetchJobDetails();
    }
}, [bearer]);

  return (
    <>
      <div className={classes.appcontainer}>
        <div className={classes.sidenav}>
          <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
            <Container fluid>
            </Container>
          </Navbar>
          <Navbar bg="light" expand={false} className="d-md-none">
            <Container fluid>
              <Button variant="success" onClick={handleShow}>
                ☰
              </Button>
            </Container>
          </Navbar>
          <DashboardNav show={show} handleClose={handleClose} />
        </div>

        <div className={classes.maincontent}>
          <Horheader />
          <div className={classes.dashBoardCont}>
            <div className={classes.firstGrid}>
              <img className={classes.firstImg} src={personal.image || defaultImg } alt="Dashboard" />
              <div className={classes.topTflex}>
                <h3>{personal.first_name} {personal.last_name}</h3>
                <p>Project Manager</p>
                <div className={classes.topThflex}>
                  <h6>EMPLOYMENT DATE</h6>
                  {personal.created_at ? <p>{personal.created_at.slice(0, 10)}</p> : null}
                </div>
              </div>
            </div>
            <div className={classes.firstGrid1}>
              <h5>CLOCK IN AND OUT</h5>
              <div className={classes.TimeAt} style={{backgroundColor: clockTime ? '#F5F5F5' : '#ACE7C766'}}>
                <div className={classes.timeWork} >
                  <h6>Time at Work</h6>
                  <p>05 Hrs : 35 Min</p>
                </div>
                <button onClick={handleClockTime} style={{backgroundColor: clockTime ? '#343A40' : '#227247'}} className={classes.clockBtn}>{clockTime ? "Clock In" : "Clock Out"}</button>
              </div>
              <div className={classes.ThFlex}>
                <div className={classes.daFlex}>
                  <h6>Clock In Time</h6>
                  <p>07:47am - 12th July, 2024</p>
                </div>
                <div className={classes.daFlex}>
                  <h6>Clock Out Time</h6>
                  <p>...</p>
                </div>
              </div>
            </div>
            <div className={classes.firstGrid2}>
              <h6>TASK IN PROGRESS</h6>
              <div className={classes.klay}>
                <img className={classes.TomImg} src={T_Icon} alt="Task Icon" />
                <div className={classes.TomDiv}>
                  <h6>Product Activation</h6>
                  <p>Product activation for the launch of the new set of noodles flavour (chicken flavour)</p>
                  <pre>Due date: 06/05/2024</pre>
                </div>
              </div>
              <div className={classes.klay}>
                <img className={classes.TomImg} src={T_Icon} alt="Task Icon" />
                <div className={classes.TomDiv}>
                  <h6>Product Activation</h6>
                  <p>Product activation for the launch of the new set of noodles flavour (chicken flavour)</p>
                  <pre>Due date: 06/05/2024</pre>
                </div>
              </div>
            </div>
            <div className={classes.firstGrid2}>
              <h6>UPCOMING MEETING</h6>
              <div className={classes.klay}>
                <img className={classes.TomImg} src={M_Icon} alt="Meeting Icon" />
                <div className={classes.TomDiv}>
                  <h6>Marketing Team Meeting</h6>
                  <p>Daily stand up with the marketing team to review strategies for the upcoming product activation</p>
                  <pre>Due date: 06/05/2024       Time: 08:00am</pre>
                </div>
              </div>
              <div className={classes.klay}>
                <img className={classes.TomImg} src={M_Icon} alt="Meeting Icon" />
                <div className={classes.TomDiv}>
                  <h6>Product Activation</h6>
                  <p>Product activation for the launch of the new set of noodles flavour (chicken flavour)</p>
                  <pre>Due date: 06/05/2024       Time: 08:00am</pre>
                </div>
              </div>
            </div>
            <div className={classes.firstGrid22}>
              <h6>UPCOMING PUBLIC HOLIDAY</h6>
              <div className={classes.klay1}>
                <img className={classes.TomImg} src={Q_Icon} alt="Holiday Icon" />
                <div className={classes.TomDiv1}>
                  <h5>June 24th and June 25th 2024</h5>
                  <pre>Salah Holiday</pre>
                </div>
              </div>
            </div>
            <div className={classes.firstGrid12}>
              <div className={classes.EmpMonth}>
                <h5>EMPLOYEE OF THE MONTH</h5>
                <p>We value you and we appreciate your hard work and dedication to the progress of this company, thank you for your service.</p>
                <div className={classes.BottomDivFlex}>
                  <h6>Antonny Micah</h6>
                  <p>Operations Manager</p>
                </div>
              </div>
              <img src={TheImg} alt="Employee of the Month" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;