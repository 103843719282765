import React, { useState, useEffect, useContext } from 'react';
// import logo from '../../Assets/dashboardlogo.png'
import bell from '../../Assets/notification.png'
import avatar from '../../Assets/avatar.png'
// import dshbrd from '../../Assets/graph.png'
// import reg from '../../Assets/receipt-2.png'
// import bdata from '../../Assets/receipt-text.png'
// import elibry from '../../Assets/book.png'
import classes from './horheader.module.css'
import { Link } from "react-router-dom";
// import { Dropdown, DropdownButton } from "react-bootstrap";
import { AuthContext } from '../../pages/utils/authContext';
import Dropdown from '../Select/Select';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';


export default function Horheader() {

    const [dropdown, setDropdown] = useState(false);
    const { logOut } = useContext(AuthContext);
    const [bearer, setBearer]=useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [personal, setPersonal] = useState({});


    const dropdownOption = {
        label: 'Logout',
        onClick: logOut
    };

    useEffect(() => {
        const readData = async () => {
            try {
                const value = await AsyncStorage.getItem('tokens');
                if (value !== null && value !== bearer) {
                    setBearer(value);
                }
            } catch (e) {
                alert('Failed to fetch the token from storage');
            }
        };
        readData();
    }, [bearer]);

    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/personal-details', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setPersonal(response.data.data || []);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);

    return (

        <div>
            <div className={classes.navbrd}>

                <div className={classes.horinfodashbrd}>
                    <div className={classes.stinfo}>
                        <p className={classes.stinfosts}>Welcome</p>
                        <p className={classes.stinfolvl}>{personal?.first_name} {personal?.last_name}</p>
                    </div>

                    <div className={classes.usrcnr}>

                        {/* <div className={classes.vrtlne}></div> */}
                        <div className={classes.usrinfo}>
                            <div className={classes.usrnminf}>

                                <p className={classes.usrpstn}>Employee Portal</p>
                                {personal.company?<p className={classes.usrnme}>{personal.company.name}</p> : null}
                            </div>
                            <div style={{position:"relative"}}>
                            <img src={avatar} className={classes.avtr} onClick={() => setDropdown(false)} />

                            <Dropdown option={dropdownOption} />
                            </div>

                        </div>
                        <img src={bell} className={classes.bel} />
                    </div>

                </div>
            </div>
        </div>

    )
}