import React, { useState, useEffect } from 'react'
import PaySlip from './promixlogo.png'
import classes from './Payslip.module.css';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

function Payslip() {

    const [bearer, setBearer] = useState('');
    const [payslip, setPayslip] = useState([]);


    useEffect(() => {
        const readData = async () => {
            try {
                const value = await AsyncStorage.getItem('tokens');
                if (value !== null && value !== bearer) {
                    setBearer(value);
                }
            } catch (e) {
                alert('Failed to fetch the token from storage');
            }
        };
        readData();
    }, [bearer]);
    

    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/payroll/employee/download-payslip', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setPayslip(response.data.data || []);
                    console.log(response.data);
                    console.log(payslip)
                } catch (error) {
                    console.error('Error fetching job details:', error);
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);


    return (
        <div className={classes.Body}>
            <div className={classes.borderdiv} >
                <div className={classes.allpagediv}>
                    <div className={classes.closerdiv}>
                        <div className={classes.promixdiv}>
                        <div>
                            <img src={PaySlip} alt='Promixlogo' className={classes.directdiv}>
                            </img>
                        </div>
                    </div>

                    <div className={classes.Payslip}>
                        <div className={classes.accounting}>
                            <p>PAYSLIP</p>
                            <p className={classes.account}>{payslip.company_id}</p>
                        </div>


                        <div className={classes.address}>
                            <p className={classes.osoba}>{payslip.address}.</p>
                         
                        </div>

                    </div>


                    <div className={classes.post}>
                        <div className={classes.name}>
                            <p>AMANDA MARCEL</p>
                        </div>

                        <div className={classes.manager}>
                            <p>Marketing Manager</p>
                            <p className={classes.Department}>Marketing Department</p>
                            <p className={classes.joined}>Joined 1st January, 2024</p>
                            <p className={classes.paid}>Pay period: 1st July to 31st of July,2024</p>
                        </div>
                    </div>

                    <div className={classes.tablediv}>
                        <div className={classes.table1}>

                            <table style={{ width: "%" }}>

                                <tr className={classes.Earn}>
                                    <th className={classes.headie}>Earnings</th>
                                    <th className={classes.headie}></th>
                                </tr>
                                <tbody>
                                    <tr className={classes.lines}>
                                        <td>Basic salary</td>
                                        <td className={classes.three}>N{payslip.salary}</td>
                                    </tr>
                                    <tr className={classes.twolines}>
                                        <td>House Allowance</td>
                                        <td className={classes.house}>N80,000</td>
                                    </tr>
                                    <tr className={classes.threelines}>
                                        <td>Transport Allowance</td>
                                        <td className={classes.transport}>N40,000</td>
                                    </tr>
                                    <tr className={classes.fourlines}>
                                        <td>Other Allowance</td>
                                        <td className={classes.other}>N30,000</td>
                                    </tr>

                                    <tr className={classes.fivelines}>
                                        <td>Total Earnings</td>
                                        <td>N450,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={classes.table2}>
                            <div className={classes.joe}>

                                <table style={{ width: "%" }}>

                                    <tr className={classes.Earn}>
                                        <th className={classes.headie}>Deductions</th>
                                        <th className={classes.headie}></th>
                                    </tr>
                                    <tbody>
                                        <tr className={classes.lines}>
                                            <td>Pension</td>
                                            <td  className={classes.pension}>N4,500</td>
                                        </tr>
                                        <tr className={classes.twolines}>
                                            <td>PAYE Tax</td>
                                            <td>N55,000</td>
                                        </tr>
                                        <tr className={classes.threelines}>
                                            <td>Loan</td>
                                            <td  className={classes.loan}>0</td>
                                        </tr>
                                        <tr className={classes.fourlines}>
                                            <td></td>
                                            <td  className={classes.zero}></td>
                                        </tr>

                                        <tr className={classes.fivelines}>
                                            <td>Total deductions</td>
                                            <td>N59,500</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div className={classes.salary}>
                        <p>  <span className={classes.salaries}> Net Salary:N390,500 </span>(Three hundred and ninety thousand, five hundred naira)</p>
                    </div>
                </div>
            </div>
        </div>
        </div >
    );
}

export default Payslip;