import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import classes from '../SignUp/Sign_up.module.css'
import loginImg from '../../Assets/loginImgImg.png'
import profileImg from '../../Assets/profileIcon.svg'

function Sign_Up() {
    return (
        <div className={classes.general}>
            <div className={classes.imgSpace}>
                <img src={loginImg} className={classes.LoginImg} alt="Sign Up" />
            </div>
            <div className={classes.inputField}>

                <span><p>H</p><p className={classes.rRed}>R</p><p>M</p></span>

                <div className={classes.botttomFlex}>

                    <div className={classes.Inpcontaer}>
                        <h4>Sign Up</h4>
                        <p>Sign up as an employee or an HR manager</p>

                        <div className={classes.inputContainer}>
                            <img className={classes.profileIcon} src={profileImg} alt="HR Manager Icon" />
                            <div className={classes.tText}>
                                <h5>HR Manager</h5>
                                <p>Manage your company staff with custom made tools for your company </p>
                            </div>
                        </div>
                        <div className={classes.inputContainer}>
                            <img className={classes.profileIcon} src={profileImg} alt="Employee Icon" />
                            <div className={classes.tText}>
                                <Link to="/Sign" className={classes.linkText}>
                                    <h5>Employee</h5>
                                </Link>
                                <p>Join your team, be in the loop of your company activities </p>
                            </div>
                        </div>
                        <button className={classes.loginBtn}>Continue</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Sign_Up;
