import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import classes from './Welcomes.module.css';
import Sidebar from '../Sidebar/Sidebar';
import { BiRightArrowAlt } from "react-icons/bi";
import welcome from '../../Assets/welcome.svg'
import { Navbar, Container, Button } from 'react-bootstrap';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import { Link } from 'react-router-dom';


const Welcomes = () => {
  const [formData, setFormData] = useState([
    {
      schoolName: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: ''
    },
    {
      schoolName: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: ''
    }
  ]);

  const navigate = useNavigate(); // Updated hook

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newFormData = [...formData];
    newFormData[index][name] = value;
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const addNewProgram = () => {
    setFormData([
      ...formData,
      {
        schoolName: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: ''
      }
    ]);
  };

  const deleteProgram = (index) => {
    const newFormData = formData.filter((_, i) => i !== index);
    setFormData(newFormData);
  };

  const handleNext = () => {
    navigate('/qualification'); // Updated navigation
  };

  const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div className={classes.container}>
      <div className={classes.sidenav}>
                        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                            <Container fluid>
                            </Container>
                        </Navbar>
                        <Navbar bg="light" expand={false} className="d-md-none">
                            <Container fluid>
                                <Button variant="success" onClick={handleShow}>
                                    ☰
                                </Button>
                            </Container>
                        </Navbar>
                        <DashboardNav show={show} handleClose={handleClose} />

                    </div>        
      <div className={classes.contentSpace}>
        <section className={classes.skip}>
          <a href=''>Skip<BiRightArrowAlt className={classes.arrowIcon} /></a>
        </section>
        <div className={classes.detailsDivs}>
                <div className={classes.detailsDiv}>
                <img src={welcome} className={classes.welcome}/>
                <h4 className={classes.welhrm}>Welcome to HRM</h4>
                <p className={classes.pwelcome}>Your onboarding process is complete, please proceed to your<br/>
                dashboard to manage your profile.</p>
                <Link to='/dashboard'><button className={classes.nextBtn}>Proceed to dashboard</button></Link>
                </div>
            </div>
        
      </div>
    </div>
  );
};

export default Welcomes;
