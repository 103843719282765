import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from './Sidebar.module.css';
import { BiLeftArrowAlt } from "react-icons/bi";
import HRM from './HRM.png';
import teacher from './teacher.png';
import user from './user.png';
import award from './award.png';
import wallet from './wallet.png';
import briefcase from './briefcase.png';

const Sidebar = ({ show, handleClose }) => {
  const [activeSection, setActiveSection] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Set active section based on current path
    const path = location.pathname.split('/')[1];
    setActiveSection(path);
  }, [location]);

  const handleSectionClick = (section, path) => {
    if (section !== activeSection) {
      setActiveSection(section);
      navigate(path);
    }
  };

  return (
    <div className={classes.imgSpace}>
      <p className='container'>
        Complete your <br /> <span className={classes.reg}>Registration</span> <br /><br />

        <div
          className={`${classes.personal1} ${activeSection === 'personal' ? classes.active : ''}`}
          onClick={() => handleSectionClick('personal', '/personal')}
          style={{ cursor: 'pointer' }}
        >
          <div className={classes.iconContainer}>
            <img src={user} className={classes.iconImage} alt="Personal Icon" />
          </div>
          <div className={classes.personalText}>
            <h5 className={activeSection === 'personal' ? classes.activeText : ''}>Personal Details</h5>
            <div className={classes.personal}>Enter Personal Details</div>
          </div>
        </div><br />

        <div
          className={`${classes.personal1} ${activeSection === 'education' ? classes.active : ''}`}
          onClick={() => handleSectionClick('education', '/education')}
          style={{ cursor: 'pointer' }}
        >
          <div className={classes.iconContainer}>
            <img src={teacher} className={classes.iconImage} alt="Education Icon" />
          </div>
          <div className={classes.personalText}>
            <h5 className={activeSection === 'education' ? classes.activeText : ''}>Educational Background</h5>
            <div className={classes.personal}>Enter your educational background details</div>
          </div>
        </div><br />

        <div
          className={`${classes.personal1} ${activeSection === 'qualification' ? classes.active : ''}`}
          onClick={() => handleSectionClick('qualification', '/qualification')}
          style={{ cursor: 'pointer' }}
        >
          <div className={classes.iconContainer}>
            <img src={award} className={classes.iconImage} alt="Professional Icon" />
          </div>
          <div className={classes.personalText}>
            <h5 className={activeSection === 'qualification' ? classes.activeText : ''}>Professional Qualifications</h5>
            <div className={classes.personal}>Enter your professional qualifications</div>
          </div>
        </div><br />

        <div
          className={`${classes.personal1} ${activeSection === 'nextkin' ? classes.active : ''}`}
          onClick={() => handleSectionClick('nextkin', '/nextkin')}
          style={{ cursor: 'pointer' }}
        >
          <div className={classes.iconContainer}>
            <img src={user} className={classes.iconImage} alt="Kin Icon" />
          </div>
          <div className={classes.personalText}>
            <h5 className={activeSection === 'nextkin' ? classes.activeText : ''}>Next of Kin</h5>
            <div className={classes.personal}>Enter your next of kin details</div>
          </div>
        </div><br />

        <div
          className={`${classes.personal1} ${activeSection === 'bank_details' ? classes.active : ''}`}
          onClick={() => handleSectionClick('bank_details', '/bank_details')}
          style={{ cursor: 'pointer' }}
        >
          <div className={classes.iconContainer}>
            <img src={wallet} className={classes.iconImage} alt="Bank Icon" />
          </div>
          <div className={classes.personalText}>
            <h5 className={activeSection === 'bank_details' ? classes.activeText : ''}>Bank Details</h5>
            <div className={classes.personal}>Enter your bank details</div>
          </div>
        </div><br />

        <div
          className={`${classes.personal1} ${activeSection === 'refereess' ? classes.active : ''}`}
          onClick={() => handleSectionClick('refereess', '/refereess')}
          style={{ cursor: 'pointer' }}
        >
          <div className={classes.iconContainer}>
            <img src={user} className={classes.iconImage} alt="Referees Icon" />
          </div>
          <div className={classes.personalText}>
            <h5 className={activeSection === 'refereess' ? classes.activeText : ''}>Referees</h5>
            <div className={classes.personal}>Enter your referees</div>
          </div>
        </div><br />

        <div
          className={`${classes.personal1} ${activeSection === 'work' ? classes.active : ''}`}
          onClick={() => handleSectionClick('work', '/work')}
          style={{ cursor: 'pointer' }}
        >
          <div className={classes.iconContainer}>
            <img src={briefcase} className={classes.iconImage} alt="Work Icon" />
          </div>
          <div className={classes.personalText}>
            <h5 className={activeSection === 'work' ? classes.activeText : ''}>Work Experience</h5>
            <div className={classes.personal}>Enter your work experience</div>
          </div>
        </div>
      </p>

      <div className={classes.signIn}>
        <BiLeftArrowAlt className={classes.arrowIcon} />
        <span><a href='#'>Sign In</a></span>
      </div>

      <div className={classes.footer}>
        <img src={HRM} alt="HRM" className={classes.hrmImage} style={{ width: '100%' }} />
      </div>
    </div>
  );
};

export default Sidebar;
