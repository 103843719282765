import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import classes from './Refereess.module.css';
import Sidebar from '../Sidebar/Sidebar';
import { BiRightArrowAlt } from "react-icons/bi";
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { BASE_URL } from "../api/api";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import AsyncStorage from "@react-native-async-storage/async-storage";

const Refereess = () => {
  const [formData, setFormData] = useState([
    {
      schoolName: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: ''
    },
    {
      schoolName: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: '',
    }
  ]);

  const navigate = useNavigate(); // Updated hook
  const [employeeDetails,setEmployeeDetails] = useState([])
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (e, index) => {
    const {name,value } = e.target;
    // console.log(name)
    const newFormData = [...employeeDetails];
    // console.log(newFormData)
    newFormData[index][name] = value;
    setEmployeeDetails(newFormData);
    // console.log(newFormData)
  };

  

  const addNewProgram = () => {
    setEmployeeDetails([
      ...employeeDetails,
      {
        schoolName: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: ''
      }
    ]);
  };

  const deleteLastProgram = () => {
    if (employeeDetails.length > 0) {
      setEmployeeDetails(employeeDetails.slice(0, -1));
    }
  };

  const readData = async () => {
    try {
        const value = await AsyncStorage.getItem('tokens');
        // console.log(value)
        if (value !== null) {
            setBearer(value);
        }
    } catch (e) {
        alert('Failed to fetch the token from storage');
    }
};

const headers = {
  "Content-Type": "application/json",
  'Authorization': `Bearer ${bearer}`,
};

const fetchBeneficiaries = async () => {
  
  const data = employeeDetails.reduce((acc, items, index) => {
    acc[`email[${index}]`] = items.email;
    acc[`first_name[${index}]`] = items.first_name;
    acc[`last_name[${index}]`] = items.last_name;
    acc[`phone[${index}]`] = items.phone;
    acc[`occupation[${index}]`] = items.occupation;
    acc[`gender[${index}]`] = items.gender;
    
    return acc;
  }, {});
  setIsLoading(true);

  try {
    const response = await axios.post(
      `${BASE_URL}/employees/update/reference/create`,
      data,
      { headers }
    );
    const results = response.data?.data;
    // console.log(results)
    navigate('/work')
  } catch (error) {
    console.log(error?.response?.data?.message)
    Object.entries(error?.response?.data?.message).forEach(([key,value]) => toast.error(value[0]) )
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate("/login");
    } else {
      const errorStatus = error.response?.data?.message;
     toast.error(errorStatus);
      // setUserData([]);
    }
  } finally {
    setIsLoading(false);
  }
};


const fetchDetailsHandler = async () => {
  console.log('submited from details')

  try {
    const response = await axios.get(
      `${BASE_URL}/employees/update/reference`,
      { headers }
    );
    const results = response?.data?.data?.Referees;
    console.log(response?.data?.data?.Referees )
    setEmployeeDetails(results)

  } catch (error) {
    console.log(error?.response?.data)
    if (error.response && error.response.status === 401) {
      navigate("/login");
    } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      // setUserData([]);
    }
  } finally {
    
  }
};


useEffect(() => {

  readData();
  }, []);

useEffect(() => {

  if (bearer) {
    fetchDetailsHandler();

  }
}, [bearer]); 


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={classes.container}>
      <Navbar bg="light" expand={false} className="d-md-none">
        <Container fluid>
          <Button variant="success" onClick={handleShow}>
            ☰
          </Button>
          <Navbar.Brand href="#">HRM</Navbar.Brand>
        </Container>
      </Navbar>

      <Sidebar show={show} handleClose={handleClose} />

      <div className={classes.contentSpace}>
        {/* <Link to ='/work'><section className={classes.skip}>
          <a href=''>Skip<BiRightArrowAlt className={classes.arrowIcon} /></a>
        </section>
        </Link> */}
        <div className={classes.heading}>
          <h3>Referees</h3>
          <p>Enter your referee details</p>
        </div>


        <div >
          <h6 style={{ textAlign: 'left', paddingLeft: '0px' }}></h6>
          {employeeDetails?.map((program, index) => (
            <div key={index}>
              <h5 style={{ textAlign: 'left', paddingLeft: '0px', paddingTop: 20 }}>{`Referees ${index + 1}`}</h5>
              <div className={classes.formContainer}>
               
                <form className={classes.form}>
                  <div className={classes.formGroup}>
                    <label htmlFor={`firstName-${index}`}>First Name</label>
                    <input
                      type="text"
                      id={`firstName-${index}`}
                      name="first_name"
                      placeholder="First Name"
                      value={program.first_name}
                      onChange={(e) => handleChange(e, index)}
                      className={classes.inputs}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor={`lastName-${index}`}>Last name</label>
                    <input
                      type="text"
                      id={`lastName-${index}`}
                      name="last_name"
                      placeholder="Last Name"
                      value={program.last_name}
                      onChange={(e) => handleChange(e, index)}
                      className={classes.inputs}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor={`email-${index}`}>Email address</label>
                    <input
                      type="email"
                      id={`email-${index}`}
                      name="email"
                      placeholder="Email"
                      value={program.email}
                      onChange={(e) => handleChange(e, index)}
                      className={classes.inputs}
                    />
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor={`phoneNo-${index}`}>Phone number</label>
                    <input
                      type="text"
                      id={`phoneNo-${index}`}
                      name="phone"
                      placeholder="phone Number"
                      value={program.phone}
                      onChange={(e) => handleChange(e, index)}
                      className={classes.inputs}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor={`gender-${index}`}>Gender</label>
                    <select
                             id={`gender-${index}`}
                             name="gender"
                             placeholder="Gender"
                             value={program.gender}
                             onChange={(e) => handleChange(e, index)}
                     className={classes.inputs} required>
                      <option value="">Select Gender</option>
                      <option value="male">
                        Male
                      </option>
                      <option value="female">
                        Female
                      </option>
                    </select>
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor={`occupation-${index}`}>Occupation</label>
                    <input
                      type="text"
                      id={`occupation-${index}`}
                      name="occupation"
                      placeholder="Occupation"
                      value={program.occupation}
                      onChange={(e) => handleChange(e, index)}
                      className={classes.inputs}
                    />
                  </div>

                </form>
              </div>
            </div>
          ))}

          {/* <br /> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 className={classes.new} style={{ fontSize: '16px', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '20px', paddingTop: 0 }}>
              <a href='#'  onClick={addNewProgram}>Add new ⊕</a>
            </h4>
            {employeeDetails?.length > 1 && (
              <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '50px', fontSize: '16px' }}>
                <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
              </h4>
            )}
          </div>
        </div>
        <div className={classes.newDiv}>
          <button className={`btn btn-success ${classes.btn}`} style={{ textAlign: 'center' }} onClick={fetchBeneficiaries}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Refereess;
