import React, { useState } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
// import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './LeaveDenied.module.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import profile from '../../Assets/ProfileIconImg.svg'
import edit from '../../Assets/EditArrow.svg'
import request from '../../Assets/request.svg'
import sheet from '../../Assets/denied.svg'

import { Navbar, Container, Button } from 'react-bootstrap';



function LeaveDenied() {
    const [key, setKey] = useState('Personal Details');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (

        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
            <Container fluid>
            </Container>
        </Navbar>
        <Navbar bg="light" expand={false} className="d-md-none">
            <Container fluid>
                <Button variant="success" onClick={handleShow}>
                    ☰
                </Button>
            </Container>
        </Navbar>
        <DashboardNav show={show} handleClose={handleClose} />

    </div>    

            <div className={classes.maincontent}>
                <Horheader />
                <div className={classes.wholediv1}>
                    <h5 className={classes.ProfileHead}>Leave</h5>
                    
                    <div className={classes.generalDiv}>
                      <div>
                        <p className={classes.requestT}>Request for Time off</p>
                        <div className={classes.divSub}>
                            <img src={request} className={classes.requestImg}/>
                            <div>
                                <h4 className={classes.timeTs}>Time off request pending</h4>
                                <p className={classes.appT}>Your application for time off between 1st of July 2024 and 6th of July 2024 is pending</p>
                            </div>
                        </div>
                        </div>
                        <div className={classes.divSub2}>
                            <img src={sheet} className={classes.sheet}/>
                            <p className={classes.timeOff}>Time off request denied</p>
                        </div>
                    </div>

                    <div className={classes.generalDiv}>
                      <div>
                        <p className={classes.requestT}>Company Leave policy</p>
                        <div className={classes.divSub}>
                            <img src={request} className={classes.requestImg}/>
                            <div>
                                <h4 className={classes.timeT}>21 Leave days available to you for this year</h4>
                                <p className={classes.appT}>You have used 5 days out of your available 21 leave days for this year</p>
                            </div>
                        </div>
                        </div>
                       
                    </div>

                    <div className={classes.generalDiv}>
                      <div>
                        <p className={classes.requestT}>Leave History</p>
                        <div className={classes.divSub}>
                            <img src={request} className={classes.requestImg}/>
                            <div>
                                <h4 className={classes.timeT}>Leave taken this year</h4>
                                <p className={classes.appT}>Leave taken for 5 days between 3rd of February, 2024 to 8th of February, 2024</p>
                            </div>
                        </div>
                        </div>
                       
                    </div>
                </div>


            </div>


        </div>

    );
}

export default LeaveDenied;