import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import classes from './Education.module.css';
import Sidebar from '../Sidebar/Sidebar';
import { BiRightArrowAlt } from "react-icons/bi";
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { BASE_URL } from "../api/api";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import AsyncStorage from "@react-native-async-storage/async-storage";


const Education = () => {
  const [formData, setFormData] = useState([
    {
      schoolName: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: ''
    },
    {
      schoolName: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: ''
    }
  ]);

  const [programName,setProgramName] = useState('')
  const [institution,setInstitution] = useState('')
  const [startDate,setStartDate] = useState('')
  const [completionDate,setCompletionDate] = useState('')
  const [employeeDetails,setEmployeeDetails] = useState([])
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate(); // Updated hook

  const handleChange = (e, index) => {
    const {name,value } = e.target;
    // console.log(name)
    const newFormData = [...employeeDetails];
    // console.log(newFormData)
    newFormData[index][name] = value;
    setEmployeeDetails(newFormData);
    // console.log(newFormData)
  };

  

  const addNewProgram = () => {
    setEmployeeDetails([
      ...employeeDetails,
      {
        schoolName: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: ''
      }
    ]);
  };

  const deleteLastProgram = () => {
    if (employeeDetails.length > 0) {
      setEmployeeDetails(employeeDetails.slice(0, -1));
    }
  };

  const readData = async () => {
    try {
        const value = await AsyncStorage.getItem('tokens');
        // console.log(value)
        if (value !== null) {
            setBearer(value);
        }
    } catch (e) {
        alert('Failed to fetch the token from storage');
    }
};

const headers = {
  "Content-Type": "application/json",
  'Authorization': `Bearer ${bearer}`,
};

const fetchBeneficiaries = async () => {
  // console.log(employeeDetails)
  // console.log(employeeDetails)
  const data = employeeDetails.reduce((acc, items, index) => {
    acc[`program_name[${index}]`] = items.program_name;
    acc[`institution_name[${index}]`] = items.institution_name;
    acc[`start_date[${index}]`] = items.start_date;
    acc[`end_date[${index}]`] = items.end_date;
    
    return acc;
  }, {});
  setIsLoading(true);

  try {
    const response = await axios.post(
      `${BASE_URL}/employees/update/education-background/create`,
      data,
      { headers }
    );
    const results = response.data?.data;
    // console.log(results)
    navigate('/qualification')
  } catch (error) {
    console.log(error?.response?.data?.message)
    Object.entries(error?.response?.data?.message).forEach(([key,value]) => toast.error(value[0]) )
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate("/login");
    } else {
      const errorStatus = error.response?.data?.message;
     toast.error(errorStatus);
      // setUserData([]);
    }
  } finally {
    setIsLoading(false);
  }
};


const fetchDetailsHandler = async () => {
  console.log('submited from details')

  try {
    const response = await axios.get(
      `${BASE_URL}/employees/update/education-background/index`,
      { headers }
    );
    const results = response?.data?.data?.education_background;
    setEmployeeDetails(results)

  } catch (error) {
    console.log(error?.response?.data)
    if (error.response && error.response.status === 401) {
      navigate("/login");
    } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      // setUserData([]);
    }
  } finally {
    
  }
};


useEffect(() => {

  readData();
  }, []);

useEffect(() => {

  if (bearer) {
    fetchDetailsHandler();

  }
}, [bearer]); 

  
 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={classes.container}>
      <Navbar bg="light" expand={false} className="d-md-none">
        <Container fluid>
          <Button variant="success" onClick={handleShow}>
            ☰
          </Button>
          <Navbar.Brand href="#">HRM</Navbar.Brand>
        </Container>
      </Navbar>

      <Sidebar show={show} handleClose={handleClose} />

      <div className={classes.contentSpace}>
        {/* <section className={classes.skip}>
          <Link to = '/qualification'><a href=''>Skip<BiRightArrowAlt className={classes.arrowIcon} /></a></Link>
        </section> */}
        <div className={classes.heading}>
          <h3>Educational Background</h3>
          <p>Enter your educational background details</p>
        </div>

        {/* {formData.map((program, index) => (
          <div key={index}>
            <h6 className={classes.academicP}>{`Academic Program ${index + 1}`}</h6>
            <div className={classes.formContainer}>
              <br />
              <form className={classes.form}>
                <div className={classes.formGroup}>
                  <label htmlFor={`schoolName-${index}`} style={{fontSize:''}}>Program name</label>
                  <input
                    type="text"
                    id={`schoolName-${index}`}
                    name="schoolName"
                    value={program.schoolName}
                    placeholder='Cyber Security'
                    // onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`degree-${index}`}>Institution attended</label>
                  <input
                    type="text"
                    id={`degree-${index}`}
                    name="degree"
                    value={program.degree}
                    placeholder='Cisco'
                    // onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`startDate-${index}`}>Start Date</label>
                  <input
                    type="date"
                    id={`startDate-${index}`}
                    name="startDate"
                    value={program.startDate}
                    // onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`endDate-${index}`}>Completion date</label>
                  <input
                    type="date"
                    id={`endDate-${index}`}
                    name="endDate"
                    value={program.endDate}
                    // onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
              </form>
            </div>
            <br />
          </div>
        ))} */}

        {employeeDetails.map((program,index) => (
          <div key={index}>
            <h6 className={classes.academicP}>{`Academic Program ${index + 1}`}</h6>
            <div className={classes.formContainer}>
              <br />
              <form className={classes.form}>
                <div className={classes.formGroup}>
                  <label htmlFor={`schoolName-${index}`} style={{fontSize:''}}>Program name</label>
                  <input
                    type="text"
                    id={`schoolName-${index}`}
                    name="program_name"
                    value={program?.program_name}
                    // value={programName[index]?.program_name}
                    // onChange={(e) => setProgramName(e.target.value)}
                    placeholder='Cyber Security'
                    onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`degree-${index}`}>Institution attended</label>
                  <input
                    type="text"
                    id={`degree-${index}`}
                    name="institution_name"
                    value={program?.institution_name}
                    // value={institution[index]?.institution_name}
                    placeholder='Cisco'
                    onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`startDate-${index}`}>Start Date</label>
                  <input
                    type="date"
                    id={`startDate-${index}`}
                    name="start_date"
                    value={program?.start_date}
                    // value={startDate[index]?.start_date}
                    onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`endDate-${index}`}>Completion date</label>
                  <input
                    type="date"
                    id={`endDate-${index}`}
                    name="end_date"
                    value={program?.end_date}
                    // value={completionDate[index]?.end_date}
                    onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
              </form>
            </div>
            <br />
          </div>
        ))}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 className={classes.new} style={{ textAlign: 'left', fontSize:'16px', fontWeight:'500', lineHeight:'19.36px', paddingLeft:'0px'}}>
            <a href='#' onClick={addNewProgram}>Add new ⊕</a>
          </h4>
          {employeeDetails.length > 1 && (
            <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight:'500', lineHeight:'19.36px', paddingLeft:'0px', fontSize:'16px' }}>
              <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
            </h4>
          )}
        </div>
        <br />
        <div className={classes.newDiv}>
          <button className={`btn btn-success ${classes.btn}`} style={{ textAlign:'center' }} onClick={fetchBeneficiaries}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Education;
