import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import classes from './NextKin.module.css';
import Sidebar from '../Sidebar/Sidebar';
import { BiRightArrowAlt } from "react-icons/bi";
import { Navbar, Container, Button } from 'react-bootstrap';
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { BASE_URL } from "../api/api";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const NextKins = () => {
 
  // const [firstName, setFirstName] = useState("");
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [firstName,setFirstName] = useState('')
  const [lastName,setLastName] = useState('')
  const [otherName,setOtherName] = useState('')
  const [email,setEmail] = useState('')
  const [phoneNo,setPhoneNo] = useState('')
  const [gender,setGender] = useState('')
  const [relationship,setRelationship] = useState('')
  const [homeAddr,setHomeAddr] = useState('')
  const [nextOfKin, setNextOfKin] = useState([
    {
      firstName: '',
      lastName: '',
      otherName: '',
      email: '',
      phoneNumber: '',
      gender: '',
      homeAddress: '',
      relationship: '',
    }
  ])
 

  const navigate = useNavigate(); // Updated hook

  
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(formData);
  // };

  const addNewProgram = () => {
    setNextOfKin([
      ...nextOfKin,
      {
        firstName: '',
        lastName: '',
        otherName: '',
        email: '',
        phoneNumber: '',
        gender: '',
        homeAddress: '',
        relationship: '',
      },
    ]);
  };
  
  const deleteLastProgram = () => {
    if (nextOfKin.length > 0) {
      setNextOfKin(nextOfKin.slice(0, -1));
    }
  };
    const readData = async () => {
      try {
          const value = await AsyncStorage.getItem('tokens');
          // console.log(value)
          if (value !== null) {
              setBearer(value);
          }
      } catch (e) {
          alert('Failed to fetch the token from storage');
      }
  };

  const headers = {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${bearer}`,
  };

  const fetchBeneficiaries = async () => {
    console.log('submited')

    setIsLoading(true);

    const data = {
      "first_name":firstName,
        "last_name": lastName,
        "email":email,
        "relationship":relationship,
        "phone_number": phoneNo,
        "address":homeAddr,
        "gender":gender,
        "other_name":otherName

    };

    try {
      const response = await axios.post(
        `${BASE_URL}/employees/update/employees-next-of-kin/create`,
        data,
        { headers }
      );
      const results = response.data?.data;
      navigate('/bank_details')
    } catch (error) {
      console.log(error?.response?.data?.message)
      Object.entries(error?.response?.data?.message).forEach(([key,value]) => toast.error(value[0]) )
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
       toast.error(errorStatus);
        // setUserData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const fetchDetailsHandler = async () => {
    console.log('submited from details')

    try {
      const response = await axios.get(
        `${BASE_URL}/employees/update/employees-next-of-kin`,
        { headers }
      );
      const results = response?.data?.data?.employees_next_of_kin;
      setFirstName(results?.first_name)
      setLastName(results?.last_name)
      setOtherName(results?.other_name)
      setEmail(results?.email)
      setPhoneNo(results?.phone_number)
      setGender(results?.gender)
      setRelationship(results?.relationship)
      setHomeAddr(results?.address)
      // setUserData(results);
      // setFirstName(results?.first_name)
      // setLastName(results?.last_name)
      
      
    } catch (error) {
      console.log(error?.response?.data)
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        // navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setUserData([]);
      }
    } finally {
      
    }
  };


  useEffect(() => {

    readData();
    }, []);

  useEffect(() => {

    if (bearer) {
      fetchDetailsHandler();

    }
  }, [bearer]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <div className={classes.container}>
      <Navbar bg="light" expand={false} className="d-md-none">
        <Container fluid>
          <Button variant="success" onClick={handleShow}>
            ☰
          </Button>
          <Navbar.Brand href="#">HRM</Navbar.Brand>
        </Container>
      </Navbar>

      <Sidebar show={show} handleClose={handleClose} />

      <div className={classes.contentSpace}>
        <div className={classes.heading}>
          <h3>Next of Kin</h3>
          <p>Enter your next of kin details</p>
        </div>
      
          <div >
            <h6 style={{ textAlign: 'left', paddingLeft:'50px' }}></h6>
            {nextOfKin?.map((nextKin, index) => (
            <div key={index}>
              <h5 style={{ textAlign: 'left', paddingLeft: '0px', paddingTop: 20 }}>{`Referees ${index + 1}`}</h5>
            <div className={classes.formContainer}>
              <br />
              <form className={classes.form}>
                <div className={classes.formGroup}>
                  <label htmlFor="name">First Name</label>
                  <input
                    type="text"
                    id="name"
                    name="schoolName"
                    placeholder='John'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="lname">Last Name</label>
                  <input
                    type="text"
                    id="lname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                   
                    name="degree"
                   
                    placeholder='Doe'
                  
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="otname">Other Name</label>
                  <input
                    type="text"
                    id="otname"
                    value={otherName}
                    onChange={(e) => setOtherName(e.target.value)}
                   
                    name="degree"
                   
                    placeholder='Doe'
                  
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                   
                    name="startDate"

                  
                    className={classes.inputs}
                  />
                </div>
                
                <div className={classes.formGroup}>
                  <label htmlFor="phnum">Phone number</label>
                  <input
                    type="text"
                    name="endDate"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                   
                   
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="gender">Gender</label>
                  <select className={classes.inputs} value={gender} required   onChange={(e) => setGender(e.target.value)}>
                    <option value="">Select Gender</option>
                    <option value="male">
                      Male
                    </option>
                    <option value="female">
                     Female
                    </option>
                    <option value="other">
                     Other
                    </option>
                  </select>
                </div>
               
                <div className={classes.formGroup}>
                  <label htmlFor="phnum">Relationship</label>
                  <input
                    type="text"
                    name="endDate"
                    value={relationship}
                    onChange={(e) => setRelationship(e.target.value)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor="address">Home address</label>
                  <textarea className={classes.inputs} value={homeAddr}  onChange={(e) => setHomeAddr(e.target.value)}>

                  </textarea>

                </div>
                
              </form>
            </div>
          </div>
        ))}
          <div style={{ display: 'flex', gap: '585px'}}>
            <h4 className={classes.new} style={{ fontSize: '16px', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '20px', paddingTop: 0 }}>
              <a href='#'  onClick={addNewProgram}>Add new ⊕</a>
            </h4>
            {nextOfKin?.length > 1 && (
              <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '50px', fontSize: '16px' }}>
                <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
              </h4>
            )}
          </div>
        </div>
        <div className={classes.newDiv}>
          <button className={`btn btn-success ${classes.btn}`} style={{ textAlign:'center' }} onClick={fetchBeneficiaries}>
           {isLoading ? 'Uploading...' : 'Next'}
          </button>
        </div>
        
      </div>
      </div>
  );
};

export default NextKins;
