import { createContext,useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css'; 
import AsyncStorage from "@react-native-async-storage/async-storage";

export const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('')
    const [token, setToken] = useState(AsyncStorage.getItem("tokens") || "")
    const [loading, setLoading] = useState(false);
    
    
    const [error,setError] = useState('')
    
    const LoginAction = async() => {
        setLoading(true)
        try {
            
            const response = await axios.post('https://hr-api.emas.ng/api/login/employee',
                {
                    email: email,
                    password: password,
                }
            )
            console.log(response)
            toast.success(response?.data?.message || 'Login successful');
            const emails = response?.data?.data?.user?.email
            const tokens = response?.data?.data?.token
            AsyncStorage.setItem("email", emails);
            AsyncStorage.setItem("tokens", tokens);
            return true;
            // navigate('/dashboard');
           

        } catch (error) {
           console.log(error)
            if (error.response) {
                        if (error.response.status === 400) {
                            setError('Invalid email or password. Please check your credentials.');
                            toast.error('Invalid email or password. Please check your credentials.')
                        } else if (error.response.status === 401) {
                            setError('Unauthorized. Please check your credentials.');
                            toast.error('Unauthorized. Please check your credentials.')
                        } else if (error.response.status === "ERR_NETWORK") {
                            setError('No Internet Connection.')
                            toast.error('No Internet Connection.')
                        }
                        else {
                            setError(`Login failed: ${error.response.data.message || 'An error occurred.'}`);
                            toast.error(`Login failed: ${error?.response?.data?.message || 'An error occurred.'}`)
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        setError('No response from server. Please try again later.');
                    } else {
                        setError(`Error: ${error.message}`);
                        toast.error(`Error: ${error.message}`);
                    }
                    toast.error(error)
                    return false;
        }
        finally{
            setLoading(false)
        }
    }

    const logOut = () => {
        setEmail("");
        setToken("");
        AsyncStorage.removeItem("tokens");
        AsyncStorage.removeItem("email");
        navigate('/');
      };

    return (
        <>
        <AuthContext.Provider value={{LoginAction,logOut,token,email,error,loading,setPassword,setEmail,password,setLoading}}>
        {children}
        </AuthContext.Provider>
        <ToastContainer />
        </>
    )
}
