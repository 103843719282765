import React, { useState } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import classes from './Document.module.css';
import frame from './frame.png';
import download from './download.png';
import { Navbar, Container, Button } from 'react-bootstrap';




function Document() {
    const [key, setKey] = useState('Personal Details');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>

            <div className={classes.appcontainer}>


                <div className={classes.sidenav}>
                    <div className={classes.sidenav}>
                        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                            <Container fluid>
                            </Container>
                        </Navbar>
                        <Navbar bg="light" expand={false} className="d-md-none">
                            <Container fluid>
                                <Button variant="success" onClick={handleShow}>
                                    ☰
                                </Button>
                            </Container>
                        </Navbar>
                        <DashboardNav show={show} handleClose={handleClose} />

                    </div>            </div>
                <div className={classes.maincontent}>
                    <Horheader />
                    <div className={classes.wholediv1}>
                        <h5 className={classes.ProfileHead}>Documents</h5>
                        <div className={classes.generalDiv}>
                            <div className={classes.gees}>
                                <div>
                                    <p className={classes.rqsttxt}>Diploma or Degree Certificate</p>
                                    <div className={classes.rqstobj}>
                                        <img src={frame} className={classes.styleicon} alt="NDA Document" />
                                        <div className={classes.dtxt}>
                                            <div className={classes.headerWithButton}>
                                                <h6 className={classes.rqsttxt2}>Proof of completion of the degree program</h6>
                                                <div className={classes.thebuttons}>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton1}>
                                                        View <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton2}>
                                                        Upload <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton}>
                                                        Download <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <p className={classes.rqsttxt3}>This is an official document issued by the educational institution confirming that the student has completed their degree program. This is a proof that you truely studied the course above </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Duplicate NDA Sections */}
                        <div className={classes.generalDiv}>
                            <div className={classes.gees}>
                                <div>
                                    <p className={classes.rqsttxt}>Certificate of Completion</p>
                                    <div className={classes.rqstobj}>
                                        <img src={frame} className={classes.styleicon} alt="NDA Document" />
                                        <div className={classes.dtxt}>
                                            <div className={classes.headerWithButton}>
                                                <h6 className={classes.rqsttxt2}>For specific programs or courses completed, especially if not tied to a degree</h6>
                                                <div className={classes.thebuttons}>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton1}>
                                                        View <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton2}>
                                                        Upload <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton}>
                                                        Download <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                </div>

                                            </div>
                                            <p className={classes.rqsttxt3}>A document that certifies the completion of specific programs or courses, especially those not tied directly to a degree (e.g., a certificate in a specific skill).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.generalDiv}>
                            <div className={classes.gees}>
                                <div>
                                    <p className={classes.rqsttxt}>Professional Certifications</p>
                                    <div className={classes.rqstobj}>
                                        <img src={frame} className={classes.styleicon} alt="NDA Document" />
                                        <div className={classes.dtxt}>
                                            <div className={classes.headerWithButton}>
                                                <h6 className={classes.rqsttxt2}>Relevant to the field of study IT certifications for tech graduates, etc</h6>
                                                <div className={classes.thebuttons}>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton1}>
                                                        View <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton2}>
                                                        Upload <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton}>
                                                        Download <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <p className={classes.rqsttxt3}>Credentials earned in specific fields (e.g., Certified Public Accountant (CPA), Project Management Professional (PMP), Certified Information Systems Security Professional (CISSP)).</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.generalDiv}>
                            <div className={classes.gees}>
                                <div>
                                    <p className={classes.rqsttxt}>Internship or Work Experience Certificate</p>
                                    <div className={classes.rqstobj}>
                                        <img src={frame} className={classes.styleicon} alt="NDA Document" />
                                        <div className={classes.dtxt}>
                                            <div className={classes.headerWithButton}>
                                                <h6 className={classes.rqsttxt2}>Documentation of any internships or relevant work experience</h6>
                                                <div className={classes.thebuttons}>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton1}>
                                                        View <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton2}>
                                                        Upload <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                    <a href="/document.pdf"
                                                        download="Document.pdf"
                                                        className={classes.downloadButton}>
                                                        Download <img src={download} className={classes.downloadIcon} alt="Download icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <p className={classes.rqsttxt3}>A document from an employer confirming the completion of an internship or work experience, detailing responsibilities and skills gained. A certificate to show that you truely understand the subject you went to study</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Document;
