import React, { useState, useEffect } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './ProfilePage.module.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import profileImg from '../../Assets/ProfileIconImg.svg';
import edit from '../../Assets/EditArrow.svg';
import { Navbar, Container, Button } from 'react-bootstrap';
import EditProfilePage from '../EditProfile Page/EditProfilePage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';



function ProfilePage() {

    const [profile, setProfile] = useState(profileImg);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfile(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const location = useLocation();
    const [key, setKey] = useState(location.state?.tab || 'Personal Details');  // Default to 'Personal Details' if no state is provided


    const navigate = useNavigate();

    const handleEditClick = (tabKey) => {
        navigate('/edit_profile', { state: { tab: tabKey } });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [loading, setLoading] = useState(false);
    const [personal, setPersonal] = useState({});
    const [education, setEducation] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [bankdetail, setBankdetail] = useState({});
    const [nextofkin, setNextofkin] = useState({});
    const [referee, setReferee] = useState([]);
    const [workExperience, setWorkExperience] = useState([]);
    const [bearer, setBearer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');



    useEffect(() => {
        const readData = async () => {
            try {
                const value = await AsyncStorage.getItem('tokens');
                if (value !== null && value !== bearer) {
                    setBearer(value);
                }
            } catch (e) {
                alert('Failed to fetch the token from storage');
            }
        };
        readData();
    }, [bearer]);

    // Fetch job details when modal is open
    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/personal-details', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setPersonal(response.data.data || []);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);
    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/employees-next-of-kin', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setNextofkin(response.data.data.employees_next_of_kin || {});
                    console.log(response.data.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);


    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/education-background/index', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setEducation(response.data.data.education_background || []);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);
    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/professional-qualification', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setQualification(response.data.data.personal_qualifications || []);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);
    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/bank-detail', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setBankdetail(response.data.data.bank_detail || []);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);
    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/reference', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setReferee(response.data.data.Referees || []);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);
    useEffect(() => {
        if (bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/update/employee-experience', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setWorkExperience(response.data.data.employee_experience || []);
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [bearer]);


    return (

        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                    <Container fluid>
                    </Container>
                </Navbar>
                <Navbar bg="light" expand={false} className="d-md-none">
                    <Container fluid>
                        <Button variant="success" onClick={handleShow}>
                            ☰
                        </Button>
                    </Container>
                </Navbar>
                <DashboardNav show={show} handleClose={handleClose} />

            </div>

            <div className={classes.maincontent}>
                {/* <div className={classes.sidenav}> */}

                <Horheader />
                <div className={classes.wholediv1}>
                    <h5 className={classes.ProfileHead}>Profile</h5>
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        defaultActiveKey="Personal Details"
                        id="justify-tab-example"
                        className={`custom-tabs ${classes.customTabs}`}
                        justify
                    >
                        <Tab eventKey="Personal Details" title="Personal Details" >
                            <div className={classes.generalDiv}>
                                <div className={classes.perFlex}>
                                    <div className={classes.perFlexChild}>
                                        <img src={personal.image || profile} />
                                        {/* <div className={classes.uploadImg}>
                                            <p>Change Photo</p>
                                            <input type='file' id='fileInput' accept="image/*" onChange={handleImageChange} />
                                            <button onClick={() => document.getElementById('fileInput').click()}>Upload Image</button>
                                        </div> */}
                                    </div>
                                    <button onClick={() => handleEditClick('Personal Details')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                </div>
                                <div className={classes.InputGrid}>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>First Name</label>
                                            <input type='text' id='name' value={personal.first_name} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Last Name</label>
                                            <input type='text' id='name' value={personal.last_name} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Other Name</label>
                                            <input type='text' id='name' value={personal.other_name} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Title</label>
                                            <input type='text' id='name' value={personal.title} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Email</label>
                                            <input type='text' id='name' value={personal.email} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Phone Number</label>
                                            <input type='text' id='name' value={personal.phone} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Gender</label>
                                            <input type='text' id='name' value={personal.gender} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Nationality</label>
                                            <input type='text' id='name' value={personal.nationality} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Date of Birth</label>
                                            <input type='text' id='name' value={personal.date_of_birth} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Place of Birth</label>
                                            <input type='text' id='name' value={personal.place_of_birth} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Marital status</label>
                                            <input type='text' id='name' value={personal.marital_status} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>State of Origin</label>
                                            <input type='text' id='name' value={personal.state_of_origin} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>L.G.A</label>
                                            <input type='text' id='name' value={personal.local_gov} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Religion</label>
                                            <input type='text' id='name' value={personal.religion} disabled /> </>}
                                    </div>

                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Blood Group</label>
                                            <input type='text' id='name' value={personal.blood_group} disabled /> </>}
                                    </div>

                                    <div className={classes.inputFlex}>
                                        {personal && <>
                                            <label htmlFor='name'>Genotype</label>
                                            <input type='text' id='name' value={personal.genotype} disabled /> </>}
                                    </div>

                                    <div className={classes.inputFlexNul}>
                                        {personal && <>
                                            <label htmlFor='name'>Home Address</label>
                                            <textarea type='text' id='name' value={personal.home_address} disabled /> </>}
                                    </div>
                                    <div className={classes.inputFlexNul}>
                                        {personal && <>
                                            <label htmlFor='name'>Permanent Address</label>
                                            <textarea type='text' id='name' value={personal.permanent_address} disabled /> </>}
                                    </div>
                                </div>

                            </div>
                            <div className={classes.secondHeight}></div>
                        </Tab>
                        <Tab eventKey="Educational details" title="Educational details" >
                            <div className={classes.generalDiv1}>
                               
                                    <div className={classes.perFlex}>
                                        <h5>ACADEMIC PROGRAM 1</h5>
                                        <button onClick={() => handleEditClick('Educational details')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                    </div>
                                    {education[0] && <>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Program name</label>
                                            {education[0] && (
                                                <input type='text' id='name' disabled value={education[0].program_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution attended</label>
                                            {education[0] && (
                                                <input type='text' id='name' disabled value={education[0].institution_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Start date</label>
                                            {education[0] && (
                                                <input type='date' id='name' disabled value={education[0].start_date || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Completion date</label>
                                            {education[0] && (
                                                <input type='date' id='name' disabled value={education[0].end_date || ''} />
                                            )}
                                        </div>

                                    </div>
                                </>}
                                <br />
                                {education[1] && <>
                                    <div className={classes.perFlex22}>
                                        <h5>ACADEMIC PROGRAM 2</h5>
                                        {/* <button onClick={() => handleEditClick('Personal Details')} className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                    </div>
                                    <div className={classes.InputGrid}>

                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Program name</label>
                                            {education[1] && (
                                                <input type='text' id='name' disabled value={education[1].program_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution attended</label>
                                            {education[1] && (
                                                <input type='text' id='name' disabled value={education[1].institution_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Start date</label>
                                            {education[1] && (
                                                <input type='date' id='name' disabled value={education[1].start_date || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Completion date</label>
                                            {education[1] && (
                                                <input type='date' id='name' disabled value={education[1].end_date || ''} />
                                            )}
                                        </div>


                                    </div>
                                </>}
                                <br />

                                {education[2] && <><div className={classes.perFlex22}>
                                    <h5>ACADEMIC PROGRAM 3</h5>
                                    {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Program name</label>
                                            {education[2] && (
                                                <input type='text' id='name' disabled value={education[2].program_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution attended</label>
                                            {education[2] && (
                                                <input type='text' id='name' disabled value={education[2].institution_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Start date</label>
                                            {education[2] && (
                                                <input type='date' id='name' disabled value={education[2].start_date || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Completion date</label>
                                            {education[2] && (
                                                <input type='date' id='name' disabled value={education[2].end_date || ''} />
                                            )}
                                        </div>

                                    </div></>}
                                {education[3] && <><div className={classes.perFlex22}>
                                    <h5>ACADEMIC PROGRAM 4</h5>
                                    {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Program name</label>
                                            {education[3] && (
                                                <input type='text' id='name' disabled value={education[3].program_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution attended</label>
                                            {education[3] && (
                                                <input type='text' id='name' disabled value={education[3].institution_name || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Start date</label>
                                            {education[3] && (
                                                <input type='date' id='name' disabled value={education[3].start_date || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Completion date</label>
                                            {education[3] && (
                                                <input type='date' id='name' disabled value={education[3].end_date || ''} />
                                            )}
                                        </div>

                                    </div></>}

                            </div>
                            <div className={classes.secondHeight}></div>
                        </Tab>
                        <Tab eventKey="Personal qualifications" title="Professional qualifications">
                            <div className={classes.generalDiv1}>
                                
                                    <div className={classes.perFlex}>
                                        <h5>PROFESSIONAL QUALIFICATION 1</h5>
                                        <button onClick={() => handleEditClick('Personal qualifications')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                    </div>
                                    {qualification[0] && <>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Qualification</label>
                                            {qualification[0] && (
                                                <input type='text' id='name' disabled value={qualification[0].qualification || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution qualification was attained from</label>
                                            {qualification[0] && (
                                                <input type='text' id='name' disabled value={qualification[0].institution || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Date attained</label>
                                            {qualification[0] && (
                                                <input type='date' id='name' disabled value={qualification[0].date_attained || ''} />
                                            )}
                                        </div>


                                    </div>
                                </>}
                                <br />
                                {qualification[1] && <>
                                    <div className={classes.perFlex22}>
                                        <h5>PROFESSIONAL QUALIFICATION 2</h5>
                                        {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                    </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Qualification</label>
                                            {qualification[1] && (
                                                <input type='text' id='name' disabled value={qualification[1].qualification || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution qualification was attained from</label>
                                            {qualification[1] && (
                                                <input type='text' id='name' disabled value={qualification[1].institution || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Date attained</label>
                                            {qualification[1] && (
                                                <input type='date' id='name' disabled value={qualification[1].date_attained || ''} />
                                            )}
                                        </div>

                                    </div>
                                </>}
                                {qualification[2] && <>
                                    <div className={classes.perFlex22}>
                                        <h5>PROFESSIONAL QUALIFICATION 3</h5>
                                        {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                    </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Qualification</label>
                                            {qualification[2] && (
                                                <input type='text' id='name' disabled value={qualification[2].qualification || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution qualification was attained from</label>
                                            {qualification[2] && (
                                                <input type='text' id='name' disabled value={qualification[2].institution || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Date attained</label>
                                            {qualification[2] && (
                                                <input type='date' id='name' disabled value={qualification[2].date_attained || ''} />
                                            )}
                                        </div>

                                    </div>
                                </>}
                                {qualification[3] && <>
                                    <div className={classes.perFlex22}>
                                        <h5>PROFESSIONAL QUALIFICATION 4 </h5>
                                        {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                    </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Qualification</label>
                                            {qualification[3] && (
                                                <input type='text' id='name' disabled value={qualification[3].qualification || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Institution qualification was attained from</label>
                                            {qualification[3] && (
                                                <input type='text' id='name' disabled value={qualification[3].institution || ''} />
                                            )}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Date attained</label>
                                            {qualification[3] && (
                                                <input type='date' id='name' disabled value={qualification[3].date_attained || ''} />
                                            )}
                                        </div>

                                    </div>
                                </>}
                            </div>
                            <div className={classes.secondHeight}></div>
                        </Tab>
                        <Tab eventKey="Next of kin" title="Next of kin">
                            <div className={classes.generalDiv2}>

                                <div className={classes.perFlex}>
                                    <h5>NEXT OF KIN</h5>
                                    <button onClick={() => handleEditClick('Next of kin')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                </div>
                                <div className={classes.InputGrid}>
                                    <div className={classes.inputFlex}>
                                        {nextofkin && <><label htmlFor='name'>First Name</label>
                                            <input type='text' id='name' disabled value={nextofkin.first_name} /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {nextofkin && <><label htmlFor='name'>Last Name</label>
                                            <input type='text' id='name' disabled value={nextofkin.last_name} /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {nextofkin && <><label htmlFor='name'>Other Name</label>
                                            <input type='text' id='name' disabled value={nextofkin.other_name} /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {nextofkin && <><label htmlFor='name'>Email Address</label>
                                            <input type='text' id='name' disabled value={nextofkin.email} /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {nextofkin && <><label htmlFor='name'>Phone Number</label>
                                            <input type='text' id='name' disabled value={nextofkin.phone_number} /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {nextofkin && <><label htmlFor='name'>Gender</label>
                                            <input type='text' id='name' disabled value={nextofkin.gender} /> </>}
                                    </div>
                                    <div className={classes.inputFlexNul}>
                                        {nextofkin && <><label htmlFor='name'> Home Address </label>
                                            <textarea type='text' id='name' disabled value={nextofkin.address} /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {nextofkin && <><label htmlFor='name'>Relationship</label>
                                            <input type='text' id='name' disabled value={nextofkin.relationship} /> </>}
                                    </div>
                                </div>

                            </div>

                        </Tab>
                        <Tab eventKey="Bank details" title="Bank details">
                            <div className={classes.generalDiv2}>
                                
                                    <div className={classes.perFlex}>
                                        <h5>BANK DETAILS</h5>
                                        <button onClick={() => handleEditClick('Bank details')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                    </div>
                                    {bankdetail && <>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Account Name</label>
                                            {bankdetail && <input type='text' disabled id='name' value={bankdetail.account_name} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Bank Name</label>
                                            {bankdetail && <input type='text' disabled id='name' value={bankdetail.bank_name} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Account number</label>
                                            {bankdetail && <input type='text' disabled id='name' value={bankdetail.account_number} />}
                                        </div>

                                    </div>
                                </>}
                            </div>
                        </Tab>
                        <Tab eventKey="Referee" title="Referee">
                            <div className={classes.generalDiv2}>
                                
                                    <div className={classes.perFlex}>
                                        <h5>REFEREE 1</h5>
                                        <button onClick={() => handleEditClick('Referee')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                    </div>
                                    {referee[0] && <>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>First Name</label>
                                            {referee[0] && <input type='text' disabled id='name' value={referee[0].first_name || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Last Name</label>
                                            {referee[0] && <input type='text' disabled id='name' value={referee[0].last_name || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Email Address</label>
                                            {referee[0] && <input type='email' disabled id='name' value={referee[0].email || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Phone Number</label>
                                            {referee[0] && <input type='text' disabled id='name' value={referee[0].phone || ''} />}
                                        </div>
                                        <div className={classes.inputFlexNul}>
                                            <label htmlFor='name'>Occupation</label>
                                            {referee[0] && <textarea type='text' disabled id='name' value={referee[0].occupation || ''} />}
                                        </div>

                                    </div>
                                </>}
                                {referee[1] && <>
                                    <div className={classes.perFlex22}>
                                        <h5>REFEREE 2</h5>
                                        <button onClick={() => handleEditClick('Referee')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                    </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>First Name</label>
                                            {referee[1] && <input type='text' disabled id='name' value={referee[1].first_name || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Last Name</label>
                                            {referee[1] && <input type='text' disabled id='name' value={referee[1].last_name || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Email Address</label>
                                            {referee[1] && <input type='email' disabled id='name' value={referee[1].email || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Phone Number</label>
                                            {referee[1] && <input type='text' disabled id='name' value={referee[1].phone || ''} />}
                                        </div>
                                        <div className={classes.inputFlexNul}>
                                            <label htmlFor='name'>Occupation</label>
                                            {referee[1] && <textarea type='text' disabled id='name' value={referee[1].occupation || ''} />}
                                        </div>

                                    </div>
                                </>}
                            </div>
                        </Tab>
                        <Tab eventKey="Work Experience" title="Work Experience">
                            <div className={classes.generalDiv1}>
                                <div className={classes.perFlex}>
                                    <h5>WORK EXPERIENCE </h5>
                                    <button onClick={() => handleEditClick('Work Experience')} className={classes.editbtn}>Edit Profile <img src={edit} /></button>
                                </div>
                                <div className={classes.InputGrid}>
                                    <div className={classes.inputFlex}>
                                        {workExperience[0] && <><label htmlFor='name'>Name of company</label>
                                            <input type='text' id='name' disabled value={workExperience[0].company || ''} /></>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {workExperience[0] && <><label htmlFor='name'>Job role</label>
                                            <input type='text' id='name' disabled value={workExperience[0].position || ''} /> </>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {workExperience[0] && <><label htmlFor='name'>Start date</label>
                                            <input type='date' id='name' disabled value={workExperience[0].start_date || ''} /></>}
                                    </div>
                                    <div className={classes.inputFlex}>
                                        {workExperience[0] && <><label htmlFor='name'>Completion date</label>
                                            <input type='date' id='name' disabled value={workExperience[0].end_date || ''} /></>}
                                    </div>

                                </div>
                                <br />
                                {workExperience[1] && <>
                                    <div className={classes.perFlex22}>
                                        <h5>WORK EXPERIENCE 2</h5>
                                        {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                    </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Name of company</label>
                                            {workExperience[1] && <input type='text' id='name' disabled value={workExperience[1].company || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Job role</label>
                                            {workExperience[1] && <input type='text' id='name' disabled value={workExperience[1].position || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Start date</label>
                                            {workExperience[1] && <input type='date' id='name' disabled value={workExperience[1].start_date || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Completion date</label>
                                            {workExperience[1] && <input type='date' id='name' disabled value={workExperience[1].end_date || ''} />}
                                        </div>

                                    </div>
                                </>}
                                {workExperience[2] && <>
                                    <div className={classes.perFlex22}>
                                        <h5>WORK EXPERIENCE 3</h5>
                                        {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                                    </div>
                                    <div className={classes.InputGrid}>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Name of company</label>
                                            {workExperience[2] && <input type='text' id='name' disabled value={workExperience[2].name || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Job role</label>
                                            {workExperience[2] && <input type='text' id='name' disabled value={workExperience[2].job_role || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Start date</label>
                                            {workExperience[2] && <input type='date' id='name' disabled value={workExperience[2].start_date || ''} />}
                                        </div>
                                        <div className={classes.inputFlex}>
                                            <label htmlFor='name'>Completion date</label>
                                            {workExperience[2] && <input type='date' id='name' disabled value={workExperience[2].end_date || ''} />}
                                        </div>

                                    </div>
                                </>}
                            </div>
                            <div className={classes.secondHeight}></div>
                        </Tab>
                    </Tabs>
                </div>


            </div>


        </div>

    );
}

export default ProfilePage;