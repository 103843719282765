import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import classes from './Navigation.module.css';
import logo from '../../Assets/dashboardlogo.png';
import dshbrd from '../../Assets/graph.png';
import dshbrdActive from '../../Assets/graph-active.png';
import reg from '../../Assets/receipt-2.png';
import regActive from '../../Assets/receipt-2-active.png';
import Appraisal from '../../Assets/award.png';
import AppraisalActive from '../../Assets/award-active.png';
import wallet from '../../Assets/empty-wallet.png';
import walletActive from '../../Assets/empty-wallet-active.png';
import leave from '../../Assets/message-text.png';
import leaveActive from '../../Assets/message-text-active.png';
import buk2 from '../../Assets/book2.png';
import buk2Active from '../../Assets/bouk2-active.png';
import profile from '../../Assets/user.png';
import profileActive from '../../Assets/user-active.png';
import login from '../../Assets/login.png';

export default function DashboardNav({ show, handleClose }) {
    const [activeItem, setActiveItem] = useState('');
    const [hoveredItem, setHoveredItem] = useState(''); // Hover state
    const location = useLocation();

    useEffect(() => {
        const pathname = location.pathname;
        const pathToItem = {
            '/dashboard': 'Dashboard',
            '/profile': 'Profile',
            '/document': 'Documents',
            '/leave': 'Leave',
            '/appraisal': 'Appraisal',
            '/senate': 'Appraisal',
            '/slip': 'Payslip',
            '/shift': 'Shifts and Schedules',
            '/attendance': 'Attendance',
            '/task': 'Task'
        };

        const active = Object.keys(pathToItem).find(key => pathname.startsWith(key));
        if (active) {
            setActiveItem(pathToItem[active]);
        }
    }, [location]);

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
    };

    const handleMouseEnter = (itemName) => {
        setHoveredItem(itemName); // Set the hovered item to trigger icon change
    };

    const handleMouseLeave = () => {
        setHoveredItem(''); // Reset hover state when not hovering
    };

    const getIcon = (itemName, defaultIcon, activeIcon) => {
        if (activeItem === itemName) {
            return hoveredItem === itemName ? defaultIcon : activeIcon; // Show default icon on hover, otherwise active icon
        }
        return defaultIcon; // Default icon for non-active items
    };

    const navItems = [
        { to: '/dashboard', name: 'Dashboard', icon: [dshbrd, dshbrdActive] },
        { to: '/profile', name: 'Profile', icon: [profile, profileActive] },
        { to: '/document', name: 'Documents', icon: [reg, regActive] },
        { to: '/leave', name: 'Leave', icon: [leave, leaveActive] },
        { to: '/appraisal', name: 'Appraisal', icon: [Appraisal, AppraisalActive] },
        { to: '/slip', name: 'Payslip', icon: [leave, leaveActive] },
        { to: '/task', name: 'Task', icon: [buk2, buk2Active] },
        { to: '/attendance', name: 'Attendance', icon: [profile, profileActive] },
        { to: '/shift', name: 'Shifts and Schedules', icon: [reg, regActive] },
        { to: '/login', name: 'Logout', icon: [login] }
    ];

    return (
        <div>
            <div className={classes.navbrd}>
                <div className={classes.navdshbrd}>
                    <div className={classes.logodiv}>
                        <p className={classes.logo}>HRM</p>
                    </div>
                    <div className={classes.gnrlcls}>
                        {navItems.map(({ to, name, icon }) => (
                            <Link
                                key={name}
                                to={to}
                                className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === name ? classes.active : ''}`}
                                onClick={() => handleItemClick(name)}
                                onMouseEnter={() => handleMouseEnter(name)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img 
                                    src={getIcon(name, icon[0], icon[1])} 
                                    className={classes.icondshbrd} 
                                    alt={name} 
                                />
                                <p className={classes.dshbrdtxt}>{name}</p>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose} className="d-md-none">
                <Offcanvas.Header closeButton className={classes.imgSpaadfce}>
                    <Offcanvas.Title>Human Resources Management</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={classes.gnrlcls1}>
                        {navItems.map(({ to, name, icon }) => (
                            <Link
                                key={name}
                                to={to}
                                className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === name ? classes.active : ''}`}
                                onClick={() => handleItemClick(name)}
                                onMouseEnter={() => handleMouseEnter(name)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img 
                                    src={getIcon(name, icon[0], icon[1])} 
                                    className={classes.icondshbrd} 
                                    alt={name} 
                                />
                                <p className={classes.dshbrdtxt}>{name}</p>
                            </Link>
                        ))}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}