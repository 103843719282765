import React, { useState } from "react";
import DashboardNav from "../../Components/Navigation.js/Navigation";
import Horheader from "../../Components/horheader/horheader";
// import 'bootstrap/dist/css/bootstrap.min.css';
import classes from "./TaskPages.module.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import profile from "../../Assets/ProfileIconImg.svg";
import edit from "../../Assets/EditArrow.svg";
import request from "../../Assets/request.svg";
import sheet from "../../Assets/sheet.svg";
import onlineicon from "../../Assets/ongoingicon.svg";
import ongoing from "../../Assets/update.svg";
import { Navbar, Container, Button } from 'react-bootstrap';


function LeavePending() {
  const [key, setKey] = useState("Personal Details");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
          <Container fluid>
          </Container>
        </Navbar>
        <Navbar bg="light" expand={false} className="d-md-none">
          <Container fluid>
            <Button variant="success" onClick={handleShow}>
              ☰
            </Button>
          </Container>
        </Navbar>
        <DashboardNav show={show} handleClose={handleClose} />      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <div className={classes.wholediv1}>
          <h5 className={classes.ProfileHead}>Task</h5>
          <p className={classes.profilep}>ONGOING TASK</p>

          <div className={classes.generalDiv}>
            <div className={classes.divSub}>
              <div className={classes.imagesDiv}>
                <img
                  src={onlineicon}
                  className={classes.requestImg}
                  alt="icons"
                />
              </div>

              <div>
                <h4 className={classes.timeT}>
                  Marketing Materials for Myra Campaign
                </h4>
                <p className={classes.appT}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                </p>
                <p className={classes.assign}>
                  Assigned members: Lara Oluyemi, Kunle Afolade, Michael Lanre
                </p>
                <small className={classes.deadline}>
                  Deadline: 15th August, 2024
                </small>
              </div>
              <div>
                <button className={classes.btnOngo}>
                  Update
                  <img src={ongoing} alt="icon" />
                </button>
              </div>
            </div>
          </div>
          <div className={classes.generalDiv}>
            <div className={classes.divSub}>
              <div className={classes.imagesDiv}>
                <img
                  src={onlineicon}
                  className={classes.requestImg}
                  alt="icons"
                />
              </div>

              <div>
                <h4 className={classes.timeT}>
                  Marketing Materials for Myra Campaign
                </h4>
                <p className={classes.appT}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                </p>
                <p className={classes.assign}>
                  Assigned members: Lara Oluyemi, Kunle Afolade, Michael Lanre
                </p>
                <small className={classes.deadline}>
                  Deadline: 15th August, 2024
                </small>
              </div>
              <div>
                <button className={classes.btnOngo}>
                  Update
                  <img src={ongoing} alt="icon" />
                </button>
              </div>
            </div>
          </div>
          <p className={classes.profilep}>COMPLETED TASK</p>

          <div className={classes.generalDiv}>
            <div className={classes.divSub}>
              <div className={classes.imagesDiv}>
                <img
                  src={onlineicon}
                  className={classes.requestImg}
                  alt="icons"
                />
              </div>

              <div>
                <h4 className={classes.timeT}>
                  Marketing Materials for Myra Campaign
                </h4>
                <p className={classes.appT}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                </p>
                <p className={classes.assign}>
                  Assigned members: Lara Oluyemi, Kunle Afolade, Michael Lanre
                </p>
                <small className={classes.deadline}>
                  Deadline: 15th August, 2024
                </small>
              </div>
              <div>
                <button className={classes.btnOngo}>
                  Update
                  <img src={ongoing} alt="icon" />
                </button>
              </div>
            </div>
          </div>
          <div className={classes.generalDiv}>
            <div className={classes.divSub}>
              <div className={classes.imagesDiv}>
                <img
                  src={onlineicon}
                  className={classes.requestImg}
                  alt="icons"
                />
              </div>

              <div>
                <h4 className={classes.timeT}>
                  Marketing Materials for Myra Campaign
                </h4>
                <p className={classes.appT}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                </p>
                <p className={classes.assign}>
                  Assigned members: Lara Oluyemi, Kunle Afolade, Michael Lanre
                </p>
                <small className={classes.deadline}>
                  Deadline: 15th August, 2024
                </small>
              </div>
              <div>
                <button className={classes.btnOngo}>
                  Update
                  <img src={ongoing} alt="icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeavePending;
