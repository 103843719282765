import React, { useState, useEffect } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import classes from './appraisal.module.css';
import { Navbar, Container, Button } from 'react-bootstrap';
import downloadIcon from './import.png';
import tick from './tick.svg';
import upload from './upload.svg'
import AppraisalModal from '../../Components/Appraisal Modal/appraisalModal';

function Appraisal() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className={classes.appcontainer}>
        <div className={classes.sidenav}>
          <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
            <Container fluid>
            </Container>
          </Navbar>
          <Navbar bg="light" expand={false} className="d-md-none">
            <Container fluid>
              <Button variant="success" onClick={handleShow}>
                ☰
              </Button>
            </Container>
          </Navbar>
          <DashboardNav show={show} handleClose={handleClose} />
        </div>

        <div className={classes.maincontent111}>
          <Horheader />
          <div className={classes.wholediv1}>
            <AppraisalModal isOpen={isModalOpen} onClose={closeModal} />

            <div className={classes.topFlexing}>
              <div>
                <h5 className={classes.ProfileHead}>Appraisal</h5>
                <p className={classes.ongoing}>ONGOING APPRAISAL</p>
              </div>
              <button onClick={openModal}>Create Personal Goals</button>
            </div>
            <div className={classes.generalDiv201}>
              <div className={classes.greenBorder}>
                <div className={classes.backgroundColor}>
                  <img src={tick} alt='' />
                  <div className={classes.pGroupthings}>
                    <p className={classes.paragraph1}>Personal Goals</p>
                    <p className={classes.paragraph2}>Download and fill your personal goals appraisal and upload it</p>
                    <p className={classes.paragraph3}>Reviewed by: Lara Oluyemi, Kunle Afolade</p>
                    <p className={classes.paragraph4}>Deadline: 15th September, 2024</p>
                  </div>
                  <button>Download <img src={downloadIcon} className={classes.download} alt="download" /></button>
                </div>
                <div className={classes.brokenBorder}>
                  <img src={upload} />
                  <p>Upload document</p>
                  <input className={classes.noShowFile} type='file' accept='pdf' />
                </div>
              </div>
              <div className={classes.greenBorder}>
                <div className={classes.backgroundColor}>
                  <img src={tick} alt='' />
                  <div className={classes.pGroupthings}>
                    <p className={classes.paragraph1}>Team Goals</p>
                    <p className={classes.paragraph2}>Download and fill your personal goals appraisal and upload it</p>
                    <p className={classes.paragraph3}>Reviewed by: Lara Oluyemi, Kunle Afolade</p>
                    <p className={classes.paragraph4}>Deadline: 15th September, 2024</p>
                  </div>
                  <button>Download <img src={downloadIcon} className={classes.download} alt="download" /></button>
                </div>
                <div className={classes.brokenBorder}>
                  <img src={upload} />
                  <p>Upload document</p>
                  <input className={classes.noShowFile} type='file' accept='pdf' />
                </div>
              </div>
              <div className={classes.greenBorder}>
                <div className={classes.backgroundColor}>
                  <img src={tick} alt='' />
                  <div className={classes.pGroupthings}>
                    <p className={classes.paragraph1}>General Goals</p>
                    <p className={classes.paragraph2}>Download and fill your personal goals appraisal and upload it</p>
                    <p className={classes.paragraph3}>Reviewed by: Lara Oluyemi, Kunle Afolade</p>
                    <p className={classes.paragraph4}>Deadline: 15th September, 2024</p>
                  </div>
                  <button>Download <img src={downloadIcon} className={classes.download} alt="download" /></button>
                </div>
                <div className={classes.brokenBorder}>
                  <img src={upload} />
                  <p>Upload document</p>
                  <input className={classes.noShowFile} type='file' accept='pdf' />
                </div>
              </div>
            </div>
            <div className={classes.emptyRow}></div>


          </div>

        </div>
      </div>
    </>
  );
}

export default Appraisal;