import React, { useState, useEffect } from 'react';
import classes from './BankDetails.module.css';
import { Navbar, Container, Button } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import { BiRightArrowAlt } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { BASE_URL } from '../api/api';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';  // React Select
// import { useNavigate } from 'react-router-dom';

const BankDetails = () => {
  const navigate = useNavigate();
  const [acctNumber, setAccountNumber] = useState('');
  const [acctName, setAccountName] = useState('');
  const [bank, setBank] = useState(null); // Updated to handle selected bank option
  const [banksList, setBanksList] = useState([]); // List of banks
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${bearer}`,
  };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);

    const data = {
      account_number: acctNumber,
      bank_name: bank?.label, // Use the selected bank's label
      account_name: acctName,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/employees/update/bank-detail/create`,
        data,
        { headers }
      );
      const results = response.data?.data;
      navigate('/refereess');
    } catch (error) {
      console.log(error?.response?.data?.message);
      Object.entries(error?.response?.data?.message).forEach(([key, value]) =>
        toast.error(value[0])
      );
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        toast.error(errorStatus);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDetailsHandler = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/employees/update/bank-detail`,
        { headers }
      );
      const results = response?.data?.data?.bank_detail;
      setAccountNumber(results?.account_number);
      setAccountName(results?.account_name);
      setBank({ label: results?.bank_name, value: results?.bank_name });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
      }
    }
  };

  // Function to fetch banks (can be replaced with an API call)
  const fetchBanks = async () => {
    // Example hardcoded bank list
    const banks = [
      { label: 'Access Bank', value: 'access_bank' },
      { label: 'Zenith Bank', value: 'zenith_bank' },
      { label: 'GTBank', value: 'gtbank' },
      // { label: 'First Bank', value: 'first_bank' },
      { label: 'Citibank Nigeria Limited ', value: 'citibank_nigeria_limited' },
      { label: 'Ecobank Nigeria Plc', value: 'ecobank_nigeria_plc' },
      { label: 'Fidelity Bank Plc', value: 'fidelity_bank_plc' },
      { label: 'First Bank Nigeria Limited', value: 'first_bank' },
      { label: 'First City Monument Bank Plc', value: 'first_city_monument_bank_plc' },
      { label: 'Globus Bank Limited', value: 'globus_bank_limited' },
      { label: 'Guaranty Trust Bank Plc', value: 'guaranty_trust_bank_plc' },
      { label: 'Heritage Banking Company Ltd', value: 'heritage_banking' },
      { label: 'Keystone Bank Limited', value: 'keystone_bank_limited' },
      { label: 'Nova Commercial Bank Limited', value: 'nova_commercial_bank' },
      { label: 'Optimus Bank', value: 'optimus_bank' },
      { label: 'Parallex Bank Ltd', value: 'parallex_bank_ltd' },
      { label: 'Polaris Bank Plc', value: 'polaris_bank_plc' },
      { label: 'Premium Trust Bank', value: 'premium_trust_bank' },
      { label: 'Providus Bank', value: 'providus_bank' },
      { label: 'Signature Bank Limited', value: 'signature_bank_limited' },
      { label: 'Stanbic IBTC Bank Plc', value: 'stanbic_ibtc_bank_plc' },
      { label: 'Standard Chartered Bank Nigeria Ltd', value: 'standard_chartered_bank' },
      { label: 'Sterling Bank Plc', value: 'sterling_bank_plc' },
      { label: 'SunTrust Bank Nigeria Limited', value: 'suntrust_bank' },
      { label: 'Titan Trust Bank Ltd', value: 'titan_trust_bank' },
      { label: 'Union Bank of Nigeria Plc', value: 'union_bank' },
      { label: 'United Bank For Africa Plc', value: 'uba' },
      { label: 'Unity Bank Plc', value: 'unity_bank' },
      { label: 'Wema Bank Plc', value: 'wema_bank' },
      { label: 'Zenith Bank Plc', value: 'zenith_bank' }
      // Add more banks or fetch them from an external API
    ];
    setBanksList(banks);
  };

  useEffect(() => {
    readData();
    fetchBanks(); // Fetch banks when the component loads
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchDetailsHandler();
    }
  }, [bearer]);

  return (
    <div>
      <div className={classes.personalTop}>
        <Navbar bg="light" expand={false} className="d-md-none">
          <Container fluid>
            <Button variant="success" onClick={handleShow}>
              ☰
            </Button>
            <Navbar.Brand href="#">HRM</Navbar.Brand>
          </Container>
        </Navbar>

        <Sidebar show={show} handleClose={handleClose} />

        <div className={classes.detailsDiv}>
          <div className={classes.topHeader}>
            <h4 className={classes.personDetails}>Bank details</h4>
            <p className={classes.pDetails}>Enter your bank details</p>
          </div>
          <div className={classes.details1}>
            <div className={classes.nameDiv}>
              <label htmlFor="number">Enter your account number</label>
              <input
                id="number"
                type="text"
                className={classes.nameInput}
                value={acctNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
            <div className={classes.nameDiv}>
              <label htmlFor="name">Enter your account name</label>
              <input
                id="name"
                type="text"
                className={classes.nameInput}
                value={acctName}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.details1}>
            <div className={classes.nameDiv}>
              <label htmlFor="bank">Select Bank</label>
              <Select
                id="bank"
                options={banksList}
                value={bank}
                onChange={(selectedOption) => setBank(selectedOption)}
                placeholder="Select a bank"
              />
            </div>
          </div>
          <button
            className={classes.nextBtn}
            onClick={fetchBeneficiaries}
            disabled={isLoading}
          >
            {isLoading ? 'Uploading...' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
