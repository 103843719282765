import React, { useState, useEffect } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './EditProfilePage.module.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import profileImg from '../../Assets/ProfileIconImg.svg'
import edit from '../../Assets/EditArrow.svg'
import ProfilePage from '../Profile Page/ProfilePage';
import { Navbar, Container, Button } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Education from '../Education/Education';



function EditProfilePage() {

  const [profile, setProfile] = useState(profileImg);
  const [loading, setLoading] = useState(false);
  const [personal, setPersonal] = useState({});
  // const [education, setEducation] = useState([]);
  const [qualification, setQualification] = useState([
    { qualification: '', institution: '', date_attained: '' }
  ]);
  const [bankdetail, setBankdetail] = useState({});
  const [nextofkin, setNextofkin] = useState({});
  const [workExperience, setWorkExperience] = useState([
    { company: '', position: '', start_date: '', end_date: '' }
  ]);
  const [bearer, setBearer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedLgas, setSelectedLgas] = useState(null);
  const [localGovernmentOptions, setLocalGovernmentOptions] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([
    {
      qualification: '',
      institution: '',
      date_attained: '',
    },
  ]);

  const [referee, setReferee] = useState([
    {
      first_name: '',
      last_name: '',
      other_name: '',
      email: '',
      phone: '',
      occupation: '',
    },
  ]);
  const [workExp, setWorkExp] = useState([
    {
      company: '',
      position: '',
      start_date: '',
      end_date: '',
    },
  ]);
  const [education, setEducation] = useState([
    {
      program_name: '',
      institution_attended: '',
      start_date: '',
      end_date: '',
    }
  ]);

  useEffect(() => {
    const readData = async () => {
      try {
        const value = await AsyncStorage.getItem('tokens');
        if (value !== null && value !== bearer) {
          setBearer(value);
        }
      } catch (e) {
        alert('Failed to fetch the token from storage');
      }
    };
    readData();
  }, [bearer]);

  useEffect(() => {
    if (bearer) {
      const fetchJobDetails = async () => {
        setErrorMessage('');
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/employees/update/personal-details', {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          });
          setPersonal(response.data.data || {});
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setErrorMessage('Failed to fetch job details');
        }
      };
      fetchJobDetails();
    }
  }, [bearer]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPersonal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitPersonal = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      // Prepare the form data for personal details
      const formData = new FormData();

      // Append all fields from the 'personal' object, ensuring 'company' is excluded
      for (const key in personal) {
        if (personal.hasOwnProperty(key) && key !== 'imageFile' && key !== 'company') {
          formData.append(key, personal[key]);
        }
      }

      // Append the actual image file (if available)
      if (personal.imageFile) {
        formData.append('image', personal.imageFile);  // Send the actual file, not the URL
      }

      if (selectedState) {
        formData.append('state_of_origin', selectedState.value);  // Assuming selectedState.value is the state's value
      }
  
      if (selectedLgas) {
        formData.append('local_gov', selectedLgas.value);  // Assuming selectedLgas.value is the LGA's value
      }

      // Send the final personal details update request
      const response = await axios.post('https://hr-api.emas.ng/api/employees/update/personal-details/create', formData, {
        headers: {
          'Authorization': `Bearer ${bearer}`,
          'Content-Type': 'multipart/form-data',  // Important for sending file data
        },
      });

      console.log('Data submitted successfully:', response.data);
    } catch (error) {
      console.error('Error updating employee:', error);
      if (error.response) {
        let errorMessage = 'An error occurred. Please try again.';
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setErrorMessage(`${errorMessage}`);
      } else {
        setErrorMessage('Failed to update employee');
      }
    } finally {
      setLoading(false);
    }
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];  // Get the selected file
    if (file) {
      // Check if it's a valid image type (jpeg, png, jpg, gif)
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfile(reader.result);  // This is for showing the image preview
        };
        reader.readAsDataURL(file);

        // Store the file itself in the 'personal' state to send it to the API
        setPersonal((prev) => ({
          ...prev,
          imageFile: file,  // Store the file itself (not a string or URL)
        }));
      } else {
        setErrorMessage('Please upload a valid image (jpeg, png, jpg, gif)');
      }
    }
  };



  useEffect(() => {
    if (bearer) {
      const fetchJobDetails = async () => {
        setErrorMessage('');
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/employees/update/employees-next-of-kin', {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          });
          setNextofkin(response.data.data.employees_next_of_kin || {});
          console.log(response.data.data);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setErrorMessage('Failed to fetch job details');
        }
      };
      fetchJobDetails();
    }
  }, [bearer]);

  const handleChangeNextofkin = (event) => {
    const { name, value } = event.target;

    setNextofkin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitNextofkin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.post('https://hr-api.emas.ng/api/employees/update/employees-next-of-kin/create', nextofkin, {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      });
      console.log('Data submitted successfully', response);
    } catch (error) {
      console.error('Error adding employee:', error);
      if (error.response) {
        let errorMessage = 'An error occurred. Please try again.';
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setErrorMessage(`${errorMessage}`);
      } else {
        setErrorMessage('Failed to add employee');
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      const fetchJobDetails = async () => {
        setErrorMessage('');
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/employees/update/education-background/index', {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          });
          setEducation(response.data.data.education_background || []);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setErrorMessage('Failed to fetch job details');
        }
      };
      fetchJobDetails();
    }
  }, [bearer]);

  const handleChangeedu = (event, index) => {
    const { name, value } = event.target;

    // Update the specific education object at the provided index
    setEducation((prevEducation) => {
      const updatedEducation = [...prevEducation];
      updatedEducation[index] = {
        ...updatedEducation[index],
        [name]: value,
      };
      return updatedEducation;
    });
  };

  const handleSubmitedu = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {

      const requestBody = {};

      education.forEach((edu, index) => {
        requestBody[`program_name[${index}]`] = edu.program_name || '';
        requestBody[`institution_name[${index}]`] = edu.institution_name || '';
        requestBody[`start_date[${index}]`] = edu.start_date || '';
        requestBody[`end_date[${index}]`] = edu.end_date || '';
      });

      const response = await axios.post('https://hr-api.emas.ng/api/employees/update/education-background/create', requestBody, {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      });

      console.log('Data submitted successfully', response);
    } catch (error) {
      console.error('Error adding employee:', error);
      console.log(error)
      if (error.response) {
        let errorMessage = 'An error occurred. Please try again.';
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setErrorMessage(`${errorMessage}`);
      } else {
        setErrorMessage('Failed to add employee');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      const fetchJobDetails = async () => {
        setErrorMessage('');
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/employees/update/professional-qualification', {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          });
          setQualification(response.data.data.personal_qualifications || []);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setErrorMessage('Failed to fetch job details');
        }
      };
      fetchJobDetails();
    }
  }, [bearer]);

  const handleChangequalification = (event, index) => {
    const { name, value } = event.target;

    setQualification((prevQualification) => {
      const updatedQualification = [...prevQualification];
      updatedQualification[index] = {
        ...updatedQualification[index],
        [name]: value,
      };
      return updatedQualification;
    });
  };

  const handleSubmitqualification = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {
      const requestBody = {};

      // Build the work experience array dynamically
      qualification.forEach((qua, index) => {
        requestBody[`qualification[${index}]`] = qua.qualification || '';
        requestBody[`institution[${index}]`] = qua.institution || '';
        requestBody[`date_attained[${index}]`] = qua.date_attained || '';
      });

      console.log('Prepared request body:', requestBody); // To check the final request data

      const response = await axios.post('https://hr-api.emas.ng/api/employees/update/qualification/create', qualification, {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      });
      console.log('Data submitted successfully', response);
    } catch (error) {
      console.error('Error adding employee:', error);
      if (error.response) {
        let errorMessage = 'An error occurred. Please try again.';
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setErrorMessage(`${errorMessage}`);
      } else {
        setErrorMessage('Failed to add employee');
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      const fetchJobDetails = async () => {
        setErrorMessage('');
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/employees/update/bank-detail', {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          });
          setBankdetail(response.data.data.bank_detail || []);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setErrorMessage('Failed to fetch job details');
        }
      };
      fetchJobDetails();
    }
  }, [bearer]);

  const handleChangeBankdetail = (event) => {
    const { name, value } = event.target;
    setBankdetail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleSubmitBankdetail = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {
      await axios.post('https://hr-api.emas.ng/api/employees/update/bank-detail/create', bankdetail, {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      });
      console.log('Data submitted successfully');
    } catch (error) {
      console.error('Error adding employee:', error);
      console.log(error)
      if (error.response) {
        let errorMessage = 'An error occurred. Please try again.';
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setErrorMessage(`${errorMessage}`);
      } else {
        setErrorMessage('Failed to add employee');
      }
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      const fetchJobDetails = async () => {
        setErrorMessage('');
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/employees/update/reference', {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          });
          setReferee(response.data.data.Referees || []);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setErrorMessage('Failed to fetch job details');
        }
      };
      fetchJobDetails();
    }
  }, [bearer]);

  const handleChangereferee = (event, index) => {
    const { name, value } = event.target;

    // Update the specific education object at the provided index
    setReferee((prevReferee) => {
      const updatedReferee = [...prevReferee];
      updatedReferee[index] = {
        ...updatedReferee[index],
        [name]: value,
      };
      return updatedReferee;
    });
  };

  const handleSubmitReferee = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {

      const requestBody = {};

      // Build the work experience array dynamically
      referee.forEach((ref, index) => {
        requestBody[`first_name[${index}]`] = ref.first_name || '';
        requestBody[`last_name[${index}]`] = ref.last_name || '';
        requestBody[`email[${index}]`] = ref.email || '';
        requestBody[`phone[${index}]`] = ref.phone || '';
        requestBody[`occupation[${index}]`] = ref.occupation || '';
      });

      console.log('Prepared request body:', requestBody);

      const response = await axios.post('https://hr-api.emas.ng/api/employees/update/reference/create', referee, {
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      });
      console.log('Data submitted successfully', response);
    } catch (error) {
      console.error('Error adding employee:', error);
      if (error.response) {
        let errorMessage = 'An error occurred. Please try again.';
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setErrorMessage(`${errorMessage}`);
      } else {
        setErrorMessage('Failed to add employee');
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      const fetchJobDetails = async () => {
        setErrorMessage('');
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/employees/update/employee-experience', {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          });
          setWorkExperience(response.data.data.employee_experience || []);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setErrorMessage('Failed to fetch job details');
        }
      };
      fetchJobDetails();
    }
  }, [bearer]);

  const handleChangeExperience = (event, index) => {
    const { name, value } = event.target;

    // Update the specific experience object at the provided index
    setWorkExperience((prevWorkExperience) => {
      const updatedWorkExperience = [...prevWorkExperience];
      updatedWorkExperience[index] = {
        ...updatedWorkExperience[index],
        [name]: value,  // Dynamically update the field by its name (e.g., 'company', 'position')
      };
      return updatedWorkExperience;
    });
  };

  const handleSubmitExperience = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      // Prepare the request body as a plain JSON object
      const requestBody = {};

      // Build the work experience array dynamically
      workExperience.forEach((exp, index) => {
        requestBody[`company[${index}]`] = exp.company || '';
        requestBody[`position[${index}]`] = exp.position || '';
        requestBody[`start_date[${index}]`] = exp.start_date || '';
        requestBody[`end_date[${index}]`] = exp.end_date || '';
      });

      console.log('Prepared request body:', requestBody); // To check the final request data

      // Post the data using axios
      const response = await axios.post('https://hr-api.emas.ng/api/employees/update/employee-experience/create', requestBody, {
        headers: {
          Authorization: `Bearer ${bearer}`,
          // Let axios handle the Content-Type for JSON submission
        },
      });

      console.log('Data submitted successfully:', response);
    } catch (error) {
      console.error('Error submitting work experience:', error);
      if (error.response) {
        setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');
      } else {
        setErrorMessage('Failed to submit work experience');
      }
    } finally {
      setLoading(false);
    }
  };






  const location = useLocation();
  const [key, setKey] = useState(location.state?.tab || 'Personal Details');  // Default to 'Personal Details' if no state is provided


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const addNewProgram = () => {
    setQualification([
      ...qualification,
      {
        qualification: '',
        institution: '',
        date_attained: '',
      },
    ]);
    setReferee([
      ...referee,
      {
        first_name: '',
        last_name: '',
        other_name: '',
        email: '',
        phone: '',
        occupation: '',
      }

    ])
    setWorkExperience([
      ...workExperience,
      {
        company: '',
        position: '',
        start_date: '',
        end_date: '',
      }
    ]);

    setEducation([
      ...education,
      {
        program_name: '',
        institution_attended: '',
        start_date: '',
        end_date: '',
      }
    ])
  };


  const deleteLastProgram = () => {
    if (qualification.length > 0) {
      setQualification(qualification.slice(0, -1));
    }
    if (referee.length > 0) {
      setReferee(referee.slice(0, -1));
    }
    if (workExperience.length > 0) {
      setWorkExperience(workExperience.slice(0, -1));
    }
    if (education.length > 0) {
      setEducation(education.slice(0, -1));
    }
  };

  const nigerianStates = [
    { value: 'abia', label: 'Abia' },
    { value: 'adamawa', label: 'Adamawa' },
    { value: 'akwa_ibom', label: 'Akwa Ibom' },
    { value: 'anambra', label: 'Anambra' },
    { value: 'bauchi', label: 'Bauchi' },
    { value: 'bayelsa', label: 'Bayelsa' },
    { value: 'benue', label: 'Benue' },
    { value: 'borno', label: 'Borno' },
    { value: 'cross_river', label: 'Cross River' },
    { value: 'delta', label: 'Delta' },
    { value: 'ebonyi', label: 'Ebonyi' },
    { value: 'edo', label: 'Edo' },
    { value: 'ekiti', label: 'Ekiti' },
    { value: 'enugu', label: 'Enugu' },
    { value: 'gombe', label: 'Gombe' },
    { value: 'imo', label: 'Imo' },
    { value: 'jigawa', label: 'Jigawa' },
    { value: 'kaduna', label: 'Kaduna' },
    { value: 'kano', label: 'Kano' },
    { value: 'katsina', label: 'Katsina' },
    { value: 'kebbi', label: 'Kebbi' },
    { value: 'kogi', label: 'Kogi' },
    { value: 'kwara', label: 'Kwara' },
    { value: 'lagos', label: 'Lagos' },
    { value: 'nasarawa', label: 'Nasarawa' },
    { value: 'niger', label: 'Niger' },
    { value: 'ogun', label: 'Ogun' },
    { value: 'ondo', label: 'Ondo' },
    { value: 'osun', label: 'Osun' },
    { value: 'oyo', label: 'Oyo' },
    { value: 'plateau', label: 'Plateau' },
    { value: 'rivers', label: 'Rivers' },
    { value: 'sokoto', label: 'Sokoto' },
    { value: 'taraba', label: 'Taraba' },
    { value: 'yobe', label: 'Yobe' },
    { value: 'zamfara', label: 'Zamfara' },
    { value: 'fct', label: 'Federal Capital Territory (FCT)' }
  ];



  const localGovernments = {
    abia: ['Aba North', 'Aba South', 'Arochukwu', 'Bende', 'Ikwuano', 'Isiala Ngwa North', 'Isiala Ngwa South', 'Isuikwuato', 'Obi Ngwa', 'Ohafia', 'Osisioma', 'Ugwunagbo', 'Ukwa East', 'Ukwa West', 'Umuahia North', 'Umuahia South', 'Umu Nneochi'],
    adamawa: ['Demsa', 'Fufore', 'Ganye', 'Girei', 'Gombi', 'Guyuk', 'Hong', 'Jada', 'Lamurde', 'Madagali', 'Maiha', 'Mayo-Belwa', 'Michika', 'Mubi North', 'Mubi South', 'Numan', 'Shelleng', 'Song', 'Toungo', 'Yola North', 'Yola South'],
    akwa_ibom: ['Abak', 'Eastern Obolo', 'Eket', 'Esit Eket', 'Essien Udim', 'Etim Ekpo', 'Etinan', 'Ibeno', 'Ibesikpo Asutan', 'Ibiono-Ibom', 'Ika', 'Ikono', 'Ikot Abasi', 'Ikot Ekpene', 'Ini', 'Itu', 'Mbo', 'Mkpat-Enin', 'Nsit-Atai', 'Nsit-Ibom', 'Nsit-Ubium', 'Obot Akara', 'Okobo', 'Onna', 'Oron', 'Oruk Anam', 'Udung-Uko', 'Ukanafun', 'Uruan', 'Urue-Offong/Oruko', 'Uyo'],
    anambra: ['Aguata', 'Anambra East', 'Anambra West', 'Anaocha', 'Awka North', 'Awka South', 'Ayamelum', 'Dunukofia', 'Ekwusigo', 'Idemili North', 'Idemili South', 'Ihiala', 'Njikoka', 'Nnewi North', 'Nnewi South', 'Ogbaru', 'Onitsha North', 'Onitsha South', 'Orumba North', 'Orumba South', 'Oyi'],
    bauchi: ['Alkaleri', 'Bauchi', 'Bogoro', 'Damban', 'Darazo', 'Dass', 'Gamawa', 'Ganjuwa', 'Giade', 'Itas/Gadau', 'Jama\'are', 'Katagum', 'Kirfi', 'Misau', 'Ningi', 'Shira', 'Tafawa Balewa', 'Toro', 'Warji', 'Zaki'],
    bayelsa: ['Brass', 'Ekeremor', 'Kolokuma/Opokuma', 'Nembe', 'Ogbia', 'Sagbama', 'Southern Ijaw', 'Yenagoa'],
    benue: ['Ado', 'Agatu', 'Apa', 'Buruku', 'Gboko', 'Guma', 'Gwer East', 'Gwer West', 'Katsina-Ala', 'Konshisha', 'Kwande', 'Logo', 'Makurdi', 'Obi', 'Ogbadibo', 'Ohimini', 'Oju', 'Okpokwu', 'Otukpo', 'Tarka', 'Ukum', 'Ushongo', 'Vandeikya'],
    borno: ['Abadam', 'Askira/Uba', 'Bama', 'Bayo', 'Biu', 'Chibok', 'Damboa', 'Dikwa', 'Gubio', 'Guzamala', 'Gwoza', 'Hawul', 'Jere', 'Kaga', 'Kala/Balge', 'Konduga', 'Kukawa', 'Kwaya Kusar', 'Mafa', 'Magumeri', 'Maiduguri', 'Marte', 'Mobbar', 'Monguno', 'Ngala', 'Nganzai', 'Shani'],
    cross_river: ['Abi', 'Akamkpa', 'Akpabuyo', 'Bakassi', 'Bekwarra', 'Biase', 'Boki', 'Calabar Municipal', 'Calabar South', 'Etung', 'Ikom', 'Obanliku', 'Obubra', 'Obudu', 'Odukpani', 'Ogoja', 'Yakurr', 'Yala'],
    delta: ['Aniocha North', 'Aniocha South', 'Bomadi', 'Burutu', 'Ethiope East', 'Ethiope West', 'Ika North East', 'Ika South', 'Isoko North', 'Isoko South', 'Ndokwa East', 'Ndokwa West', 'Okpe', 'Oshimili North', 'Oshimili South', 'Patani', 'Sapele', 'Udu', 'Ughelli North', 'Ughelli South', 'Ukwuani', 'Uvwie', 'Warri North', 'Warri South', 'Warri South West'],
    ebonyi: ['Abakaliki', 'Afikpo North', 'Afikpo South', 'Ebonyi', 'Ezza North', 'Ezza South', 'Ikwo', 'Ishielu', 'Ivo', 'Izzi', 'Ohaozara', 'Ohaukwu', 'Onicha'],
    edo: ['Akoko-Edo', 'Egor', 'Esan Central', 'Esan North-East', 'Esan South-East', 'Esan West', 'Etsako Central', 'Etsako East', 'Etsako West', 'Igueben', 'Ikpoba-Okha', 'Oredo', 'Orhionmwon', 'Ovia North-East', 'Ovia South-West', 'Owan East', 'Owan West', 'Uhunmwonde'],
    ekiti: ['Ado-Ekiti', 'Efon', 'Ekiti East', 'Ekiti South-West', 'Ekiti West', 'Emure', 'Gbonyin', 'Ido/Osi', 'Ijero', 'Ikere', 'Ikole', 'Ilejemeje', 'Irepodun/Ifelodun', 'Ise/Orun', 'Moba', 'Oye'],
    enugu: ['Aninri', 'Awgu', 'Enugu East', 'Enugu North', 'Enugu South', 'Ezeagu', 'Igbo Etiti', 'Igbo Eze North', 'Igbo Eze South', 'Isi Uzo', 'Nkanu East', 'Nkanu West', 'Nsukka', 'Oji River', 'Udenu', 'Udi', 'Uzo-Uwani'],
    gombe: ['Akko', 'Balanga', 'Billiri', 'Dukku', 'Funakaye', 'Gombe', 'Kaltungo', 'Kwami', 'Nafada', 'Shongom', 'Yamaltu/Deba'],
    imo: ['Aboh Mbaise', 'Ahiazu Mbaise', 'Ehime Mbano', 'Ezinihitte', 'Ideato North', 'Ideato South', 'Ihitte/Uboma', 'Ikeduru', 'Isiala Mbano', 'Isu', 'Mbaitoli', 'Ngor Okpala', 'Njaba', 'Nkwerre', 'Nwangele', 'Obowo', 'Oguta', 'Ohaji/Egbema', 'Okigwe', 'Onuimo', 'Orlu', 'Orsu', 'Oru East', 'Oru West', 'Owerri Municipal', 'Owerri North', 'Owerri West'],
    jigawa: ['Auyo', 'Babura', 'Biriniwa', 'Birnin Kudu', 'Buji', 'Dutse', 'Gagarawa', 'Garki', 'Gumel', 'Guri', 'Gwaram', 'Gwiwa', 'Hadejia', 'Jahun', 'Kafin Hausa', 'Kaugama', 'Kazaure', 'Kiri Kasama', 'Kiyawa', 'Maigatari', 'Malam Madori', 'Miga', 'Ringim', 'Roni', 'Sule Tankarkar', 'Taura', 'Yankwashi'],
    kaduna: ['Birnin Gwari', 'Chikun', 'Giwa', 'Igabi', 'Ikara', 'Jaba', "Jema'a", 'Kachia', 'Kaduna North', 'Kaduna South', 'Kagarako', 'Kajuru', 'Kaura', 'Kauru', 'Kubau', 'Kudan', 'Lere', 'Makarfi', 'Sabon Gari', 'Sanga', 'Soba', 'Zangon Kataf', 'Zaria'],
    kano: ['Ajingi', 'Albasu', 'Bagwai', 'Bebeji', 'Bichi', 'Bunkure', 'Dala', 'Dambatta', 'Dawakin Kudu', 'Dawakin Tofa', 'Doguwa', 'Fagge', 'Gabasawa', 'Garko', 'Garun Mallam', 'Gaya', 'Gezawa', 'Gwale', 'Gwarzo', 'Kabo', 'Kano Municipal', 'Karaye', 'Kibiya', 'Kiru', 'Kumbotso', 'Kunchi', 'Kura', 'Madobi', 'Makoda', 'Minjibir', 'Nasarawa', 'Rano', 'Rimin Gado', 'Rogo', 'Shanono', 'Sumaila', 'Takai', 'Tarauni', 'Tofa', 'Tsanyawa', 'Tudun Wada', 'Ungogo', 'Warawa', 'Wudil'],
    katsina: ['Bakori', 'Batagarawa', 'Batsari', 'Baure', 'Bindawa', 'Charanchi', 'Dandume', 'Danja', 'Dan Musa', 'Daura', 'Dutsi', 'Dutsin Ma', 'Faskari', 'Funtua', 'Ingawa', 'Jibia', 'Kafur', 'Kaita', 'Kankara', 'Kankia', 'Katsina', 'Kurfi', 'Kusada', 'Mai\'Adua', 'Malumfashi', 'Mani', 'Mashi', 'Matazu', 'Musawa', 'Rimi', 'Sabuwa', 'Safana', 'Sandamu', 'Zango'],
    kebbi: ['Aleiro', 'Arewa Dandi', 'Argungu', 'Augie', 'Bagudo', 'Birnin Kebbi', 'Bunza', 'Dandi', 'Fakai', 'Gwandu', 'Jega', 'Kalgo', 'Koko/Besse', 'Maiyama', 'Ngaski', 'Sakaba', 'Shanga', 'Suru', 'Wasagu/Danko', 'Yauri', 'Zuru'],
    kogi: ['Adavi', 'Ajaokuta', 'Ankpa', 'Bassa', 'Dekina', 'Ibaji', 'Idah', 'Igalamela-Odolu', 'Ijumu', 'Kabba/Bunu', 'Kogi', 'Lokoja', 'Mopa-Muro', 'Ofu', 'Ogori/Magongo', 'Okehi', 'Okene', 'Olamaboro', 'Omala', 'Yagba East', 'Yagba West'],
    kwara: ['Asa', 'Baruten', 'Edu', 'Ekiti', 'Ifelodun', 'Ilorin East', 'Ilorin South', 'Ilorin West', 'Irepodun', 'Isin', 'Kaiama', 'Moro', 'Offa', 'Oke Ero', 'Oyun', 'Pategi'],
    lagos: ['Agege', 'Ajeromi-Ifelodun', 'Alimosho', 'Amuwo-Odofin', 'Apapa', 'Badagry', 'Epe', 'Eti-Osa', 'Ibeju-Lekki', 'Ifako-Ijaiye', 'Ikeja', 'Ikorodu', 'Kosofe', 'Lagos Island', 'Lagos Mainland', 'Mushin', 'Ojo', 'Oshodi-Isolo', 'Shomolu', 'Surulere'],
    nasarawa: ['Akwanga', 'Awe', 'Doma', 'Karu', 'Keana', 'Keffi', 'Kokona', 'Lafia', 'Nasarawa', 'Nasarawa Eggon', 'Obi', 'Toto', 'Wamba'],
    niger: ['Agaie', 'Agwara', 'Bida', 'Borgu', 'Bosso', 'Chanchaga', 'Edati', 'Gbako', 'Gurara', 'Katcha', 'Kontagora', 'Lapai', 'Lavun', 'Magama', 'Mariga', 'Mashegu', 'Mokwa', 'Moya', 'Paikoro', 'Rafi', 'Rijau', 'Shiroro', 'Suleja', 'Tafa', 'Wushishi'],
    ogun: ['Abeokuta North', 'Abeokuta South', 'Ado-Odo/Ota', 'Egbado North', 'Egbado South', 'Ewekoro', 'Ifo', 'Ijebu East', 'Ijebu North', 'Ijebu North East', 'Ijebu Ode', 'Ikenne', 'Imeko Afon', 'Ipokia', 'Obafemi Owode', 'Odeda', 'Odogbolu', 'Ogun Waterside', 'Remo North', 'Shagamu'],
    ondo: ['Akoko North-East', 'Akoko North-West', 'Akoko South-East', 'Akoko South-West', 'Akure North', 'Akure South', 'Ese Odo', 'Idanre', 'Ifedore', 'Ilaje', 'Ile Oluji/Okeigbo', 'Irele', 'Odigbo', 'Okitipupa', 'Ondo East', 'Ondo West', 'Ose', 'Owo'],
    osun: ['Atakumosa East', 'Atakumosa West', 'Aiyedaade', 'Aiyedire', 'Boluwaduro', 'Boripe', 'Ede North', 'Ede South', 'Egbedore', 'Ejigbo', 'Ife Central', 'Ife East', 'Ife North', 'Ife South', 'Ifedayo', 'Ifelodun', 'Ila', 'Ilesa East', 'Ilesa West', 'Irepodun', 'Irewole', 'Isokan', 'Iwo', 'Obokun', 'Odo Otin', 'Ola Oluwa', 'Olorunda', 'Oriade', 'Orolu'],
    oyo: ['Afijio', 'Akinyele', 'Atiba', 'Atisbo', 'Egbeda', 'Ibadan North', 'Ibadan North-East', 'Ibadan North-West', 'Ibadan South-East', 'Ibadan South-West', 'Ibarapa Central', 'Ibarapa East', 'Ibarapa North', 'Ido', 'Irepo', 'Iseyin', 'Itesiwaju', 'Iwajowa', 'Kajola', 'Lagelu', 'Ogbomosho North', 'Ogbomosho South', 'Ogo Oluwa', 'Olorunsogo', 'Oluyole', 'Ona Ara', 'Orelope', 'Ori Ire', 'Oyo East', 'Oyo West', 'Saki East', 'Saki West', 'Surulere'],
    plateau: ['Barkin Ladi', 'Bassa', 'Bokkos', 'Jos East', 'Jos North', 'Jos South', 'Kanam', 'Kanke', 'Langtang North', 'Langtang South', 'Mangu', 'Mikang', 'Pankshin', 'Qua\'an Pan', 'Riyom', 'Shendam', 'Wase'],
    rivers: ['Abua/Odual', 'Ahoada East', 'Ahoada West', 'Akuku-Toru', 'Andoni', 'Asari-Toru', 'Bonny', 'Degema', 'Eleme', 'Emohua', 'Etche', 'Gokana', 'Ikwerre', 'Khana', 'Obio/Akpor', 'Ogba/Egbema/Ndoni', 'Ogu/Bolo', 'Okrika', 'Omuma', 'Opobo/Nkoro', 'Oyigbo', 'Port Harcourt', 'Tai'],
    sokoto: ['Binji', 'Bodinga', 'Dange Shuni', 'Gada', 'Goronyo', 'Gudu', 'Gwadabawa', 'Illela', 'Isa', 'Kebbe', 'Kware', 'Rabah', 'Sabon Birni', 'Shagari', 'Silame', 'Sokoto North', 'Sokoto South', 'Tambuwal', 'Tangaza', 'Tureta', 'Wamako', 'Wurno', 'Yabo'],
    taraba: ['Ardo Kola', 'Bali', 'Donga', 'Gashaka', 'Gassol', 'Ibi', 'Jalingo', 'Karim Lamido', 'Kumi', 'Lau', 'Sardauna', 'Takum', 'Ussa', 'Wukari', 'Yorro', 'Zing'],
    yobe: ['Bade', 'Bursari', 'Damaturu', 'Fika', 'Fune', 'Geidam', 'Gujba', 'Gulani', 'Jakusko', 'Karasuwa', 'Machina', 'Nangere', 'Nguru', 'Potiskum', 'Tarmuwa', 'Yunusari', 'Yusufari'],
    zamfara: ['Anka', 'Bakura', 'Birnin Magaji/Kiyaw', 'Bukkuyum', 'Bungudu', 'Gummi', 'Gusau', 'Kaura Namoda', 'Maradun', 'Maru', 'Shinkafi', 'Talata Mafara', 'Tsafe', 'Zurmi'],
    fct: ['Abaji', 'Bwari', 'Gwagwalada', 'Kuje', 'Kwali', 'Municipal Area Council']
  }


  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedLgas(null);

    const lgas = localGovernments[selectedOption?.value] || [];
    setLocalGovernmentOptions(lgas.map(lga => ({ value: lga, label: lga })));
  };


  const handlGovChange = (selectedOption) => {
    setSelectedLgas(selectedOption)
  }
  return (

    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
          <Container fluid>
          </Container>
        </Navbar>
        <Navbar bg="light" expand={false} className="d-md-none">
          <Container fluid>
            <Button variant="success" onClick={handleShow}>
              ☰
            </Button>
          </Container>
        </Navbar>
        <DashboardNav show={show} handleClose={handleClose} />

      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <div className={classes.wholediv1}>
          <h5 className={classes.ProfileHead}>Profile</h5>
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            id="justify-tab-example"
            className={`custom-tabs ${classes.customTabs}`}
            justify
          >
            <Tab eventKey="Personal Details" title="Personal Details" >
              <div className={classes.generalDiv}>
                <div className={classes.perFlexChild}>
                  <img src={personal.imageFile ? URL.createObjectURL(personal.imageFile) : personal.image} alt="Profile" />
                  <div className={classes.uploadImg}>
                    <p>Change Photo</p>
                    <input type="file" id="fileInput" accept="image/jpeg, image/png, image/jpg, image/gif" onChange={handleImageChange} style={{ display: 'none' }} />
                    <button type="button" onClick={() => document.getElementById('fileInput').click()}>Upload Image</button>
                  </div>
                </div>

                <div className={classes.InputGrid}>
                  <div className={classes.inputFlex}>
                    <label htmlFor='name'>First Name</label>
                    <input type='text' id='first_name' name='first_name' value={personal.first_name || ''}
                      onChange={handleChange} />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor='name'>Last Name</label>
                    <input type='text' id='last_name' name='last_name' value={personal.last_name || ''}
                      onChange={handleChange} />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="Name">Other Name</label>
                    <input
                      type="text"
                      id="other_name"
                      name="other_name"
                      value={personal.other_name || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="title">Title</label>
                    <select
                      className={classes.inputs}

                      id='title'
                      name='title'
                      value={personal.title || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select title</option>
                      <option value="Mr">Mr.</option>
                      <option value="Mrs">Mrs.</option>
                      <option value="Miss">Miss.</option>
                      <option value="Chief">Chief</option>
                      <option value="Honourable">Honourable</option>
                    </select>
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor='name'>Email Address</label>
                    <input type='email' id='email' name='email' value={personal.email || ''}
                      onChange={handleChange} />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor='name'>Phone Number</label>
                    <input type='text' id='phone' name='phone' value={personal.phone || ''}
                      onChange={handleChange} />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="gender">Gender</label>
                    <select className={classes.inputs} id='gender'
                      name='gender'
                      value={personal.gender || ''}
                      onChange={handleChange} >

                      <option value="">Select value</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="nationality">Nationality</label>
                    <select
                      className={classes.inputs}
                      id='nationality'
                      name='nationality'
                      value={personal.nationality || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select Nationality</option>
                      <option value="Nigeria">Nigeria</option>
                    </select>
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor='name'>Date of Birth</label>
                    <input type='date' id='date_of_birth' name='date_of_birth' value={personal.date_of_birth || ''}
                      onChange={handleChange} />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="pob">Place of Birth</label>
                    <input
                      type="text"
                      id='pob'
                      name="place_of_birth"
                      value={personal.place_of_birth || ''}
                      onChange={handleChange}
                      className={classes.inputs}
                    />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="marital">Marital Status</label>
                    <select
                      className={classes.inputs}
                      id='marital_status'
                      name='marital_status'
                      value={personal.marital_status || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>

                    </select>
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="state">State of Origin</label>
                    <Select
                      value={selectedState}
                      onChange={handleStateChange}
                      options={nigerianStates}
                      placeholder="Select a state"
                      isSearchable
                      className={classes.inputsReactSelect}
                    />
                    
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="local">L.G.A</label>
                    <Select
                      value={selectedLgas}
                      onChange={handlGovChange}
                      options={localGovernmentOptions}
                      placeholder="Select a state"
                      isSearchable
                      className={classes.inputsReactSelect}
                    />
                    
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor="religion">Religion</label>
                    <select
                      className={classes.inputs}
                      id='religion'
                      name='religion'
                      value={personal.religion || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select Religion</option>
                      <option value="christain">Christian</option>
                      <option value="islam">Islam</option>
                      <option value="others">Others</option>

                    </select>
                  </div>

                  <div className={classes.inputFlex}>
                    <label htmlFor="blood">Blood Group</label>
                    <select className={classes.inputs} id='blood_group' name='blood_group' value={personal.blood_group || ''}
                      onChange={handleChange}>
                      <option value="">Select Blood Group</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB">AB</option>
                    </select>
                  </div>

                  <div className={classes.inputFlex}>
                    <label htmlFor="gen">Genotype</label>
                    <select className={classes.inputs} id='genotype' name='genotype' value={personal.genotype || ''}
                      onChange={handleChange}>
                      <option value="">Select Genotype</option>
                      <option value="AA">AA</option>
                      {/* <option value="aa">AA</option> */}
                      <option value="AS">AS</option>
                      <option value="SS">SS</option>
                    </select>
                  </div>

                  <div className={classes.inputFlexNul}>
                    <label htmlFor='name'>Home address</label>
                    <textarea type='text' id='home_address' name='home_address' value={personal.home_address || ''}
                      onChange={handleChange} />
                  </div>
                  <div className={classes.inputFlexNul}>
                    <label htmlFor="address">Permanant address</label>
                    <textarea type='text' id='permanent_address' name='permanent_address' value={personal.permanent_address || ''}
                      onChange={handleChange}>
                    </textarea>
                  </div>

                  <button type='submit' onClick={handleSubmitPersonal} className={classes.saveChange1} >{loading ? 'Saving...' : 'Save changes'}</button>
                </div>
                {errorMessage && <p>{errorMessage}</p>}

              </div>
              <div className={classes.secondHeight}></div>
            </Tab>

            <Tab eventKey="Educational details" title="Educational details">
              <div className={classes.generalDiv1}>
                {education.map((edu, index) => (
                  <div key={index}>
                    <h5 style={{ paddingTop: 30 }} className={classes.perFlex123}>{`Educational Details ${index + 1}`}</h5>
                    <div className={classes.InputGrid}>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`program_name-${index}`}>Program Name</label>
                        <input
                          type="text"
                          id={`program_name-${index}`}
                          value={edu.program_name || ''}
                          name='program_name'
                          onChange={(e) => handleChangeedu(e, index)}
                        />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`institution_name-${index}`}>Institution attended</label>
                        <input
                          type='text'
                          id={`institution_name-${index}`}
                          value={edu.institution_name || ''}
                          name='institution_name'
                          onChange={(e) => handleChangeedu(e, index)}
                        />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor='start_date'>Start date</label>
                        <input
                          type='date'
                          id='start_date'
                          name='start_date'
                          value={edu.start_date || ''}
                          onChange={(e) => handleChangeedu(e, index)}
                        />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor='end_date'>Completion date</label>
                        <input
                          type='date'
                          id='end_date'
                          name='end_date'
                          value={education[0]?.end_date || ''}
                          onChange={(e) => handleChangeedu(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <div style={{ display: 'flex', gap: '605px' }}>
                  <h4 className={classes.new} style={{ textAlign: 'left', fontSize: '16px', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px' }}>
                    <a href='#' onClick={addNewProgram}>Add new ⊕</a>
                  </h4>
                  {education.length > 1 && (
                    <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px', fontSize: '16px' }}>
                      <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
                    </h4>
                  )}
                </div>
                <br />
                <br />
                <div className={classes.newDiv}>
                  <button type='submit' onClick={handleSubmitedu} className={classes.btn35}>{loading ? 'Saving...' : 'Save Changes'}</button>
                </div>
              </div>
              <div className={classes.secondHeight}></div>
            </Tab>

            <Tab eventKey="Personal qualifications" title="Professional qualifications">
              <div className={classes.generalDiv1}>

                {qualification.map((qua, index) => (
                  <div key={index}>
                    <h5 style={{ paddingTop: 30 }} className={classes.perFlex123}>{`Professional Qualification ${index + 1}`}</h5>
                    <div className={classes.InputGrid}>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`qualificationName-${index}`}>Qualification</label>
                        <input type='text' name='qualification'
                          id={`qualificationName-${index}`}
                          placeholder="Qualification Name"
                          value={qua.qualification}
                          onChange={handleChangequalification}
                          className={classes.inputs} />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`institution-${index}`}>Institution qualification was attained from</label>
                        <input type='text' name='institution'
                          id={`institution-${index}`}
                          placeholder="Institution"
                          value={qua.institution}
                          onChange={handleChangequalification}
                          className={classes.inputs} />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`dateAwarded-${index}`}>Date attained</label>
                        <input type='date' name='date_attained'
                          id={`dateAwarded-${index}`}
                          onChange={handleChangequalification}
                          value={qua.date_attained}
                          className={classes.inputs} />
                      </div>
                    </div>
                  </div>
                ))}


                <div style={{ display: 'flex', gap: '605px' }}>
                  <h4 className={classes.new} style={{ textAlign: 'left', fontSize: '16px', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px' }}>
                    <a href='#' onClick={addNewProgram}>Add new ⊕</a>
                  </h4>
                  {qualification.length > 1 && (
                    <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px', fontSize: '16px' }}>
                      <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
                    </h4>
                  )}
                </div>
                <br />
                <br />
                <div className={classes.newDiv}>
                  <button type='submit' onClick={handleSubmitqualification} className={classes.btn35}>Save changes</button>
                </div>
              </div>
              <br />
              <div className={classes.secondHeight}></div>
            </Tab>
            <Tab eventKey="Next of kin" title="Next of kin">
              <div className={classes.generalDiv2}>

                <div>
                  <h5 style={{ paddingTop: 30 }} className={classes.perFlex123}>Next of Kin </h5>
                  <div className={classes.InputGrid}>
                    <div className={classes.inputFlex}>
                      <label>First Name</label>
                      <input type='text'

                        onChange={handleChangeNextofkin}
                        name='first_name'
                        value={nextofkin.first_name || ''} />
                    </div>
                    <div className={classes.inputFlex}>
                      <label>Last Name</label>
                      <input type='text'

                        onChange={handleChangeNextofkin}
                        name='last_name'
                        value={nextofkin.last_name || ''} />
                    </div>
                    <div className={classes.inputFlex}>
                      <label>Other Name</label>
                      <input type='text'
                        onChange={handleChangeNextofkin}
                        name='other_name'
                        value={nextofkin.other_name || ''} />
                    </div>
                    <div className={classes.inputFlex}>
                      <label>Email Address</label>
                      <input type='email'
                        onChange={handleChangeNextofkin}
                        name='email'
                        value={nextofkin.email || ''} />
                    </div>
                    <div className={classes.inputFlex}>
                      <label>Phone Number</label>
                      <input type='text'
                        onChange={handleChangeNextofkin}
                        name='first_name'
                        value={nextofkin.phone_number || ''} />
                    </div>
                    <div className={classes.inputFlex}>
                      <label>Gender</label>
                      <select className={classes.inputs}
                        name='gender'
                        value={nextofkin.gender || ''}
                        onChange={handleChangeNextofkin} >
                        <option value="">Select value</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className={classes.inputFlexNul}>
                      <label>Home address</label>
                      <textarea
                        type='text'
                        onChange={handleChangeNextofkin}
                        name='address'
                        value={nextofkin.address || ''} />

                    </div>
                    <div className={classes.inputFlex}>
                      <label>Relationship</label>
                      <input type='text'
                        onChange={handleChangeNextofkin}
                        name='relationship'
                        value={nextofkin.relationship || ''} />
                    </div>
                  </div>
                </div>




                <br />
                <br />
                <div className={classes.newDiv}>
                  <button type='submit' onClick={handleSubmitNextofkin} className={classes.btn35}>{loading ? 'Saving...' : 'Save Changes'}</button>
                </div>
              </div>
              <div className={classes.secondHeight}></div>
            </Tab>
            <Tab eventKey="Bank details" title="Bank details">
              <div className={classes.generalDiv2}>
                <div className={classes.perFlex}>
                  <h5>BANK DETAILS</h5>
                  {/* <button className={classes.editbtn}>Edit Profile <img src={edit} /></button> */}
                </div>
                <div className={classes.InputGrid}>
                  <div className={classes.inputFlex}>
                    <label htmlFor='account_name'>Account Name</label>
                    <input type='text' id='account_name' name='account_name' value={bankdetail.account_name || ''}
                      onChange={handleChangeBankdetail} />
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor='bank_name'>Bank Name</label>
                    <select className={classes.selectInput} id='bank_name' name='bank_name' value={bankdetail.bank_name || ''}
                      onChange={handleChangeBankdetail}>
                      <option value='UBA'>UBA</option>
                      <option value='FIRST BANK'>FIRST BANK</option>
                      <option value='WEMA'>WEMA</option>
                      <option value='GTB'>GTB</option>
                    </select>
                  </div>
                  <div className={classes.inputFlex}>
                    <label htmlFor='account_number'>Account number</label>
                    <input type='text' id='account_number' name='account_number' value={bankdetail.account_number || ''}
                      onChange={handleChangeBankdetail} />
                  </div>
                  <div />
                  <button type='submit' onClick={handleSubmitBankdetail} className={classes.saveChange} >{loading ? 'Saving...' : 'Save changes'}</button>

                </div>
              </div>
            </Tab>
            <Tab eventKey="Referee" title="Referee">
              <div className={classes.generalDiv2}>
                {referee.map((ref, index) => (
                  <div key={index}>
                    <h5 style={{ paddingTop: 30 }} className={classes.perFlex123}>{`Referee ${index + 1}`}</h5>
                    <div className={classes.InputGrid}>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`name-${index}`}>First Name</label>
                        <input type='text'
                          id={`name-${index}`}
                          name='first_name'
                          value={ref.first_name}
                          onChange={(e) => handleChangereferee(e, index)} />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`name-${index}`}>Last Name</label>
                        <input type='text'
                          id={`name-${index}`}
                          name='last_name'
                          value={ref.last_name}
                          onChange={(e) => handleChangereferee(e, index)} />
                      </div>
                  
                      <div className={classes.inputFlex}>
                        <label htmlFor={`email-${index}`}>Email Address</label>
                        <input type='email'
                          id={`email-${index}`}
                          name='email'
                          value={ref.email}
                          onChange={(e) => handleChangereferee(e, index)} />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`name-${index}`}>Phone Number</label>
                        <input type='text'
                          id={`name-${index}`}
                          name='phone'
                          value={ref.phone}
                          onChange={(e) => handleChangereferee(e, index)} />
                      </div>
                      <div className={classes.inputFlexNul}>
                        <label htmlFor={`name-${index}`}>Occupation</label>
                        <textarea
                          type='text'
                          id={`name-${index}`}
                          name='occupation'
                          value={ref.occupation}
                          onChange={(e) => handleChangereferee(e, index)} />

                      </div>
                    </div>
                  </div>
                ))}

                <div style={{ display: 'flex', gap: '605px' }}>
                  <h4 className={classes.new} style={{ textAlign: 'left', fontSize: '16px', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px' }}>
                    <a href='#' onClick={addNewProgram}>Add new ⊕</a>
                  </h4>
                  {referee.length > 1 && (
                    <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px', fontSize: '16px' }}>
                      <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
                    </h4>
                  )}
                </div>
                <br />
                <br />
                <div className={classes.newDiv}>
                  <button type='submit' onClick={handleSubmitReferee} className={classes.btn35}>{loading ? 'Saving...' : 'Save Changes'}</button>
                </div>
              </div>
              <div className={classes.secondHeight}></div>
            </Tab>
            <Tab eventKey="Work Experience" title="Work Experience">
              <div className={classes.generalDiv1}>
                {workExperience.map((exp, index) => (
                  <div key={index}>
                    <h5 style={{ paddingTop: 30 }} className={classes.perFlex123}>{`Work Experience ${index + 1}`}</h5>
                    <div className={classes.InputGrid}>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`name-${index}`}>Name of company</label>
                        <input
                          type='text'
                          id={`name-${index}`}
                          value={exp.company}
                          name="company"
                          onChange={(e) => handleChangeExperience(e, index)}
                        />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`name-${index}`} >Job role</label>
                        <input
                          type='text'
                          id={`name-${index}`}
                          value={exp.position}
                          name="position"
                          onChange={(e) => handleChangeExperience(e, index)} />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`name-${index}`} >Start date</label>
                        <input
                          type='date'
                          id={`name-${index}`}
                          value={exp.start_date}
                          name='start_date'
                          onChange={(e) => handleChangeExperience(e, index)}
                        />
                      </div>
                      <div className={classes.inputFlex}>
                        <label htmlFor={`name-${index}`}>Completion date</label>
                        <input
                          type='date'
                          id={`name-${index}`}
                          value={exp.end_date}
                          name='end_date'
                          onChange={(e) => handleChangeExperience(e, index)} />
                      </div>

                    </div>
                  </div>
                ))}



                <div style={{ display: 'flex', gap: '605px' }}>
                  <h4 className={classes.new} style={{ textAlign: 'left', fontSize: '16px', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px' }}>
                    <a href='#' onClick={addNewProgram}>Add new ⊕</a>
                  </h4>
                  {workExperience.length > 1 && (
                    <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px', fontSize: '16px' }}>
                      <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
                    </h4>
                  )}
                </div>
                <br />
                <br />
                <div className={classes.newDiv}>
                  <button type='submit' className={classes.btn35} onClick={handleSubmitExperience}>{loading ? 'Saving...' : 'Save changes'}</button>
                </div>
              </div>
              <div className={classes.secondHeight}></div>
            </Tab>
          </Tabs>
        </div >


      </div >


    </div >

  );
}

export default EditProfilePage;