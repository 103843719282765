import React, { useState } from 'react';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import classes from './leaveWelcome.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import welcome from '../../Assets/WelcomeIcon.svg'
import { Navbar, Container, Button } from 'react-bootstrap';




function LeaveWelcome (){

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                            <Container fluid>
                            </Container>
                        </Navbar>
                        <Navbar bg="light" expand={false} className="d-md-none">
                            <Container fluid>
                                <Button variant="success" onClick={handleShow}>
                                    ☰
                                </Button>
                            </Container>
                        </Navbar>
                        <DashboardNav show={show} handleClose={handleClose} />
                        </div>

            <div className={classes.maincontent}>
                <Horheader />
                <div>
                    <h5 className={classes.LeaveTxt}>Leave</h5>
        <div className={classes.personalTop}>
          
           
            <div className={classes.detailsDivs1}>
                
                <div className={classes.detailsDiv1}>
                <img src={welcome} className={classes.welcome1}/>
                <p className={classes.pwelcome1}>Your request has been submitted</p>
                <Link to={'/dashboard'}><button className={classes.nextBtn1}>Back to dashboard</button></Link>
                </div>
            </div>
        </div>
    </div>


            </div>


        </div>

    );
}

export default LeaveWelcome;