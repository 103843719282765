import React, { useState, useEffect } from 'react';
import classes from './Qualification.module.css';
import Sidebar from '../Sidebar/Sidebar';
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { BASE_URL } from "../api/api";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import AsyncStorage from "@react-native-async-storage/async-storage";


const Qualification = () => {
  const [formData, setFormData] = useState([
    {
      qualificationName: '',
      institution: '',
      dateAwarded: '',
    },
    {
      qualificationName: '',
      institution: '',
      dateAwarded: '',
    },
  ]);
  const [programName, setProgramName] = useState('')
  const [institution, setInstitution] = useState('')
  const [startDate, setStartDate] = useState('')
  const [completionDate, setCompletionDate] = useState('')
  const [employeeDetails, setEmployeeDetails] = useState([])
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate(); // Updated hook

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    // console.log(name)
    const newFormData = [...employeeDetails];
    // console.log(newFormData)
    newFormData[index][name] = value;
    setEmployeeDetails(newFormData);
    // console.log(newFormData)
  };



  const addNewProgram = () => {
    setEmployeeDetails([
      ...employeeDetails,
      {
        schoolName: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: '',
        enDate: ''
      }
    ]);
  };

  const deleteLastProgram = () => {
    if (employeeDetails.length > 0) {
      setEmployeeDetails(employeeDetails.slice(0, -1));
    }
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      // console.log(value)
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };

  const headers = {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${bearer}`,
  };

  const fetchBeneficiaries = async () => {

    const data = employeeDetails.reduce((acc, items, index) => {
      acc[`qualification[${index}]`] = items.qualification;
      acc[`institution[${index}]`] = items.institution;
      acc[`date_attained[${index}]`] = items.date_attained;

      return acc;
    }, {});
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/employees/update/professional-qualification/create`,
        data,
        { headers }
      );
      const results = response.data?.data;
      // console.log(results)
      navigate('/nextkin')
    } catch (error) {
      console.log(error?.response?.data?.message)
      Object.entries(error?.response?.data?.message).forEach(([key, value]) => toast.error(value[0]))
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        toast.error(errorStatus);
        // setUserData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const fetchDetailsHandler = async () => {
    console.log('submited from details')

    try {
      const response = await axios.get(
        `${BASE_URL}/employees/update/professional-qualification`,
        { headers }
      );
      const results = response?.data?.data?.personal_qualifications;
      setEmployeeDetails(results)

    } catch (error) {
      console.log(error?.response?.data)
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        // setUserData([]);
      }
    } finally {

    }
  };


  useEffect(() => {

    readData();
  }, []);

  useEffect(() => {

    if (bearer) {
      fetchDetailsHandler();

    }
  }, [bearer]);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={classes.container}>
      <Navbar bg="light" expand={false} className="d-md-none">
        <Container fluid>
          <Button variant="success" onClick={handleShow}>
            ☰
          </Button>
          <Navbar.Brand href="#">HRM</Navbar.Brand>
        </Container>
      </Navbar>

      <Sidebar show={show} handleClose={handleClose} />

      <div className={classes.contentSpace}>
        <div className={classes.heading}>
          <h3 >Professional Qualifications</h3>
          <p>Enter your professional qualifications</p>
        </div>

        {employeeDetails.map((program, index) => (
          <div key={index}>
            <h5 style={{ textAlign: 'left', paddingLeft: '0px', paddingTop: 20 }}>{`Professional Qualification ${index + 1}`}</h5>
            <div className={classes.formContainer}>
              <form className={classes.form}>
                <div className={classes.formGroup}>
                  <label htmlFor={`qualificationName-${index}`}>Qualification</label>
                  <input
                    type="text"
                    id={`qualificationName-${index}`}
                    name="qualification"
                    placeholder="Qualification Name"
                    value={program?.qualification}
                    onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`institution-${index}`} style={{ fontSize: '' }}>Institution qualification was obtained from</label>
                  <input
                    type="text"
                    id={`institution-${index}`}
                    name="institution"
                    placeholder="Institution"
                    value={program.institution}
                    onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
                <div className={classes.formGroup}>
                  <label htmlFor={`dateAwarded-${index}`}>Date Attained</label>
                  <input
                    type="date"
                    id={`dateAwarded-${index}`}
                    name="date_attained"
                    value={program.date_attained}
                    onChange={(e) => handleChange(e, index)}
                    className={classes.inputs}
                  />
                </div>
              </form>
            </div>
          </div>
        ))}


        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 className={classes.new} style={{ textAlign: 'left', fontSize: '16px', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px' }}>
            <a href='#' onClick={addNewProgram}>Add new ⊕</a>
          </h4>
          {employeeDetails.length > 1 && (
            <h4 className={classes.delete} style={{ textAlign: 'left', textDecoration: 'none', fontWeight: '500', lineHeight: '19.36px', paddingLeft: '0px', fontSize: '16px' }}>
              <a href='#' onClick={deleteLastProgram} >Delete Last ⊗</a>
            </h4>
          )}
        </div>
        <br />
        <br />
        <div className={classes.newDiv}>
          <button className={`btn btn-success ${classes.btn}`} style={{ textAlign: 'center' }} onClick={fetchBeneficiaries}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Qualification;
