import React, { useState } from "react";
import classes from './Attendance.module.css';
import DashboardNav from '../Navigation.js/Navigation';
import Horheader from '../horheader/horheader';
import { Navbar, Container, Button } from 'react-bootstrap';

const Attendance = () => {
  // Default date value in YYYY-MM-DD format
  const defaultDate = '2024-01-07';
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
          <Container fluid>
          </Container>
        </Navbar>
        <Navbar bg="light" expand={false} className="d-md-none">
          <Container fluid>
            <Button variant="success" onClick={handleShow}>
              ☰
            </Button>
          </Container>
        </Navbar>
        <DashboardNav show={show} handleClose={handleClose} />
      </div>
      <div className={classes.maincontent}>
        <Horheader />
        <div className={classes.wholediv1}>
          <div className={classes.generalDivNew}>
            <div className={classes.statContainer}>
              <h5 className={classes.ProfileHead}>Attendance</h5>
              <div className={classes.overallStatisticsContainer}>
                <p className={classes.rqsttxt}>OVERALL STATISTICS</p>
                <div className={classes.statItems}>
                  <div className={classes.stat}>
                    <p>Number of hours worked Today</p>
                    <h3 style={{ color: '#2D995F' }}>07:12 hours</h3>
                  </div>
                  <div className={classes.stat}>
                    <p>Number of hours worked This Week</p>
                    <h3 style={{ color: '#FFBA08' }}>28 hours</h3>
                  </div>
                  <div className={classes.stat}>
                    <p>Number of hours worked This Month</p>
                    <h3 style={{ color: '#00C7BE' }}>90 hours</h3>
                  </div>
                  <div className={classes.stat}>
                    <p>Number of hours worked This Year</p>
                    <h3 style={{ color: '#007AFF' }}>247 hours</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.filterContainer} ${classes.container}`}>
              <label className={classes.filterLabel} style={{ paddingLeft: '20px' }}>Filter:</label>
              <div className={classes.filterGroup}>
                <label>Start date</label>
                <input type="date" style={{ color: 'gray' }} value={defaultDate} className={classes.dateInput} />
              </div>
              <div className={classes.filterGroup}>
                <label>End date</label>
                <input type="date" style={{ color: 'gray' }} value={defaultDate} className={classes.dateInput} />
              </div>
              <p className={classes.applyButton}>Apply</p>
              <p className={classes.resetButtonRight}>Reset</p> {/* Moved to the right */}
            </div>

            <div className={classes.tableContainer}>
              <table className={classes.attendanceTable}>
                <thead>
                  <tr>
                    <th className="sn-col">S/N</th>
                    <th>Date</th>
                    <th>Punch In Time</th>
                    <th>Punch Out Time</th>
                    <th>Hours Worked</th>
                    <th>Overtime</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="sn-col">1</td>
                    <td>01/07/2024</td>
                    <td>08:11am</td>
                    <td>04:38pm</td>
                    <td>9 hrs</td>
                    <td>0 hrs</td>
                  </tr>
                  <tr>
                    <td className="sn-col">2</td>
                    <td>01/07/2024</td>
                    <td>08:11am</td>
                    <td>04:38pm</td>
                    <td>9 hrs</td>
                    <td>0 hrs</td>
                  </tr>
                  <tr>
                    <td className="sn-col">3</td>
                    <td>01/07/2024</td>
                    <td>08:11am</td>
                    <td>04:38pm</td>
                    <td>9 hrs</td>
                    <td>0 hrs</td>
                  </tr>
                  <tr>
                    <td className="sn-col">4</td>
                    <td>01/07/2024</td>
                    <td>08:11am</td>
                    <td>04:38pm</td>
                    <td>9 hrs</td>
                    <td>0 hrs</td>
                  </tr>
                  <tr>
                    <td className="sn-col">5</td>
                    <td>01/07/2024</td>
                    <td>08:11am</td>
                    <td>04:38pm</td>
                    <td>9 hrs</td>
                    <td>0 hrs</td>
                  </tr>
                  <tr>
                    <td className="sn-col">6</td>
                    <td>01/07/2024</td>
                    <td>08:11am</td>
                    <td>04:38pm</td>
                    <td>9 hrs</td>
                    <td>0 hrs</td>
                  </tr>
                  <tr>
                    <td className="sn-col">7</td>
                    <td>01/07/2024</td>
                    <td>08:11am</td>
                    <td>04:38pm</td>
                    <td>9 hrs</td>
                    <td>0 hrs</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
