import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import classes from '../SignUp_Employee/Sign_upEmp.module.css';
import loginImg from '../../Assets/loginImgImg.png';
// import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../utils/authContext';
import 'react-toastify/dist/ReactToastify.css';
import { GoEyeClosed } from "react-icons/go";
import spinner from '../../Assets/spinner.gif'

function Sign_UpEmp() {
    const [messageColor, setMessageColor] = useState('');
    const [showMessage, setShowMessage] = useState('');
    const navigate = useNavigate(); 
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const {setEmail,email,password,setPassword,LoginAction,loading,setLoading}= useContext(AuthContext)

    const handleLogin = async () => {
        const isSuccess = await LoginAction(); // Call LoginAction and get the result
        if (isSuccess) {
            navigate('/personal'); // Navigate if login is successful
        } else {
            openModal(); // Optionally open a modal or handle the error
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            handleLogin(); 
        }
    };

    return (
        <div className={classes.general}>
            <div className={classes.imgSpace}>
                <img src={loginImg} className={classes.LoginImg} alt="Login" />
            </div>
            <div className={classes.inputField}>
                <span>
                    <p>H</p>
                    <p className={classes.rRed}>R</p>
                    <p>M</p>
                </span>
                <div className={classes.botttomFlex}>
                    <div className={classes.Inpcontaer}>
                        <h4>Log In</h4>
                        <p>Log In as an employee</p>
                        <div className={classes.inputContainer}>
                            <div className={classes.Inputcnt}>
                                <label htmlFor='email'>Email addresser</label>
                                <input
                                    type='email'
                                    id='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='Johndoe@gmail.com'
                                    onKeyDown={handleKeyDown} 
                                    required
                                />
                            </div>
                        </div>

                        <div className={classes.inputContainer}>
                            <div className={classes.Inputcnt}>
                                <label htmlFor='password'>Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={handleKeyDown} 
                                    required
                                />
                                <p
                                    onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                    <GoEyeClosed/> 
                                ) : (
                                    '👁️'
                                )}
                                    </p>
                            </div>
                        </div>

                        <button
                            style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F' }}
                            className={classes.loginBtn}
                            onClick={handleLogin}
                            disabled={loading}
                        >
                            {loading && (<img src={spinner} alt='' className={classes.spinnerStyle} />)}
                            {loading ? 'Logging in, please wait....' : 'Log In'}
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sign_UpEmp;
